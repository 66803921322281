<template>
  <div>
    <div>
      <!-- Display the clinical reference ID before the tabs are shown -->
      <p>
        Clinical Reference ID: <b>{{ patient_clinical_reference }}</b>
      </p>
    </div>

    <!-- Tabs -->
    <div>
      <b-card no-body>
        <b-tabs card>
          <!-- Image uploading and severity scores -->
          <b-tab title="Images">
            <!-- Record the completion status, lighting condition and number of clicians reviewing the images -->
            <h3>Image collection information</h3>
            <div>
              <b-row align-h="center">
                <b-col sm="auto">
                  <b-form-group label="Record completion">
                    <b-form-select
                      v-model="recordCompletionValue"
                      :options="recordCompletionOptions"
                      required
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col sm="auto">
                  <b-form-group label="Lighting">
                    <b-form-select
                      v-model="lightingValue"
                      :options="lightingOptions"
                      required
                      aria-required="true"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col sm="auto">
                  <b-form-group label="Number of scorers">
                    <b-form-input
                      v-model="numScorerValue"
                      type="number"
                      min="1"
                      required
                      oninput="validity.valid||(value='');"
                      autocomplete="off"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

            <div class="add-button-container">
              <b-button
                variant="primary"
                @click="onSave('Image collection information')"
                >Save</b-button
              >
            </div>

            <b-card-text>
              <h3>Images &amp; severity scores</h3>
              <!-- <b-row align-h="center">
                <b-col sm="auto">
                  <b-form-group label="Image Quality">
                    <b-form-select
                      v-model="imageQualityValue"
                      :options="imageQualityOptions"
                      aria-required="true"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col sm="auto">
                  <b-form-group label="Image Type">
                    <b-form-select
                      v-model="imageTypeValue"
                      :options="imageTypeOptions"
                      aria-required="true"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col sm="auto">
                  <b-form-group label="Body site">
                    <b-form-select
                      v-model="imageLocationValue"
                      :options="imageLocationOptions"
                      aria-required="true"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col sm="auto">
                  <b-form-group label="Representative site">
                    <b-row align-h="center">
                      <b-form-checkbox
                        v-model="imageRepresentativeSiteValue"
                        aria-required="true"
                      ></b-form-checkbox>
                    </b-row>
                  </b-form-group>
                </b-col>
                <b-col sm="auto">
                  <b-form-group label="Android">
                    <b-row align-h="center">
                      <b-form-checkbox
                        v-model="imageAndroidValue"
                        aria-required="true"
                      ></b-form-checkbox>
                    </b-row>
                  </b-form-group>
                </b-col>                
              </b-row> -->

              <!-- size argument in PictureInput is the file size limit in MB -->
              <div>
                <PictureInput
                  ref="pictureInput"
                  width="400"
                  height="300"
                  margin="4"
                  accept="image/*"
                  size="20"
                  v-on:load="onImageLoad"
                  :is-editing-image-severity="is_editing_image_severity"
                  v-on:cancel-update-image-record="onCancelUpdateImageRecord"
                ></PictureInput>
              </div>
              <!-- 
              <p></p>
              <p>Severity scores</p> -->
              <!-- <div class="severity-upload-container">
                <b-form-group label="Cracking">
                  <b-form-select
                    v-model="crackingScore"
                    :options="severityOptions"
                    aria-required="true"
                  ></b-form-select>
                </b-form-group>

                <b-form-group label="Dryness">
                  <b-form-select
                    v-model="drynessScore"
                    :options="severityOptions"
                    aria-required="true"
                  ></b-form-select>
                </b-form-group>

                <b-form-group label="Erythema">
                  <b-form-select
                    v-model="erythemaScore"
                    :options="severityOptions"
                    aria-required="true"
                  ></b-form-select>
                </b-form-group>

                <b-form-group label="Excoriation">
                  <b-form-select
                    v-model="excoriationScore"
                    :options="severityOptions"
                    aria-required="true"
                  ></b-form-select>
                </b-form-group>

                <b-form-group label="Exudation">
                  <b-form-select
                    v-model="exudationScore"
                    :options="severityOptions"
                    aria-required="true"
                  ></b-form-select>
                </b-form-group>

                <b-form-group label="Lichenification">
                  <b-form-select
                    v-model="lichenificationScore"
                    :options="severityOptions"
                    aria-required="true"
                  ></b-form-select>
                </b-form-group>

                <b-form-group label="Oedema">
                  <b-form-select
                    v-model="oedemaScore"
                    :options="severityOptions"
                    aria-required="true"
                  ></b-form-select>
                </b-form-group>
              </div> -->

              <!-- <div class="add-button-container">
                <div> -->
              <!-- adding new image and severity scores mode -->
              <!-- <b-button
                    variant="primary"
                    v-if="!is_editing_image_severity"
                    @click="onAddImageRecord"
                    >Add</b-button
                  > -->
              <!-- editing an existing image and severity scores record -->
              <!-- <b-button
                    variant="primary"
                    v-if="is_editing_image_severity"
                    @click="onUpdateImageRecord"
                    >Update</b-button
                  > -->
              <!-- Add a small space between the Update and Cancel buttons -->
              <!-- &nbsp;
                  <b-button
                    variant="secondary"
                    v-if="is_editing_image_severity"
                    @click="onCancelUpdateImageRecord"
                    >Cancel</b-button
                  > -->
              <!-- </div>
              </div> -->

              <h2>Uploaded images and severity scores</h2>
              <!-- https://stackoverflow.com/questions/71498836/how-to-display-images-in-vuetify-datatable-using-paths -->

              <p><b>NOTES</b>:</p>
              <p>
                1. Click on the corresponding row in the table below to edit a
                record.
              </p>
              <!-- <p>
                2. The asterisk (&#10033;) indicates the representative site(s).
              </p> -->

              <div
                class="table-container"
                style="margin-left: 0; margin-right: 0"
              >
                <div class="table-body">
                  <v-data-table
                    height="60vh"
                    :headers="new_headers"
                    :items="new_items"
                    fixed-header
                    hide-default-footer
                    disable-pagination
                    @click:row="onImageRowClicked"
                  >
                    <template #header="{ header }">
                      <tr>
                        <!-- Loop through each header and center align the text -->
                        <th
                          v-for="(item, index) in header"
                          :key="index"
                          :class="{
                            'text-center': [
                              'Image',
                              'Quality',
                              'Type',
                              'Body site',
                            ].includes(item.text),
                          }"
                          :style="{ width: item.width + '%' }"
                        >
                          {{ item.text }}
                        </th>
                      </tr>
                    </template>
                    <!-- This template is used to have custom html elements in your specific column of the table -->
                    <template v-slot:item.imgsrc="{ item }">
                      <!-- You can uncomment the line below to see the actual item data. Also you can use 'v-for' or 'v-if' or other vue capabilities, if your item data is more complex.  -->
                      <!--          <span>{{item}}</span>-->
                      <!-- <img width="100px" :src=item.imgsrc> -->
                      <img
                        class="table-image"
                        v-if="check_condition(item)"
                        :src="item.imgsrc"
                        @click="showImagePreview(item.imgsrc)"
                        style="margin: 0 auto; display: block"
                      />
                    </template>

                    <!-- Custom template for Image Quality column (dropdown list) -->
                    <template v-slot:item.imageQualityValue="{ item }">
                      <b-form-select
                        v-model="item.imageQualityValue"
                        :options="imageQualityOptions"
                        outlined
                        size="sm"
                        class="table-body-site text-center"
                        style="margin: 0 auto; display: block"
                        @change="onImageRowClicked(item)"
                        @focus="onImageRowClicked(item)"                        
                      ></b-form-select>
                    </template>

                    <!-- Custom template for Image Type column (dropdown list) -->
                    <template v-slot:item.imageTypeValue="{ item }">
                      <b-form-select
                        v-model="item.imageTypeValue"
                        :options="imageTypeOptions"
                        outlined
                        size="sm"
                        class="table-body-site text-center"
                        style="margin: 0 auto; display: block"
                        @change="onImageRowClicked(item)"
                        @focus="onImageRowClicked(item)"                        
                      ></b-form-select>
                    </template>

                    <!-- Custom template for Body site column (dropdown list) -->
                    <template v-slot:item.imageLocationValue="{ item }">
                      <b-form-select
                        v-model="item.imageLocationValue"
                        :options="imageLocationOptions"
                        outlined
                        size="sm"
                        class="table-body-site text-center"
                        style="margin: 0 auto; display: block"
                        @change="onImageRowClicked(item)"
                        @focus="onImageRowClicked(item)"                        
                      ></b-form-select>
                    </template>

                    <!-- Custom template for imageRepresentativeSiteValue column -->
                    <template
                      v-slot:item.imageRepresentativeSiteValue="{ item }"
                    >
                      <!-- Radio button to toggle the value -->
                      <b-form-checkbox
                        v-model="item.imageRepresentativeSiteValue"
                        size="sm"
                        class="text-center"
                        style="margin: 0 auto; display: block"
                        @focus="onImageRowClicked(item)"   
                        autocomplete="off"                     
                      ></b-form-checkbox>
                    </template>

                    <!-- Custom template for Android column -->
                    <template v-slot:item.imageAndroidValue="{ item }">
                      <!-- Radio button to toggle the value -->
                      <b-form-checkbox
                        v-model="item.imageAndroidValue"
                        size="sm"
                        class="text-center"
                        style="margin: 0 auto; display: block"
                        @focus="onImageRowClicked(item)"   
                        autocomplete="off"                     
                      ></b-form-checkbox>
                    </template>

                    <!-- Custom template for Cracking column (editable) -->
                    <template v-slot:item.cracking="{ item }">
                      <b-form-input
                        v-model="item.cracking"
                        outlined
                        class="text-center"
                        @focus="onImageRowClicked(item)"
                        autocomplete="off"
                      ></b-form-input>
                    </template>

                    <!-- Custom template for Dryness column (editable) -->
                    <template v-slot:item.dryness="{ item }">
                      <b-form-input
                        v-model="item.dryness"
                        outlined
                        class="text-center"
                        @focus="onImageRowClicked(item)"
                        autocomplete="off"
                      ></b-form-input>
                    </template>

                    <!-- Custom template for Erythema column (editable) -->
                    <template v-slot:item.erythema="{ item }">
                      <b-form-input
                        v-model="item.erythema"
                        outlined
                        class="text-center"
                        @focus="onImageRowClicked(item)"
                        autocomplete="off"
                      ></b-form-input>
                    </template>

                    <!-- Custom template for Excoriation column (editable) -->
                    <template v-slot:item.excoriation="{ item }">
                      <b-form-input
                        v-model="item.excoriation"
                        outlined
                        class="text-center"
                        @focus="onImageRowClicked(item)"
                        autocomplete="off"
                      ></b-form-input>
                    </template>

                    <!-- Custom template for Exudation column (editable) -->
                    <template v-slot:item.exudation="{ item }">
                      <b-form-input
                        v-model="item.exudation"
                        outlined
                        class="text-center"
                        @focus="onImageRowClicked(item)"
                        autocomplete="off"
                      ></b-form-input>
                    </template>

                    <!-- Custom template for Lichenification column (editable) -->
                    <template v-slot:item.lichenification="{ item }">
                      <b-form-input
                        v-model="item.lichenification"
                        outlined
                        class="text-center"
                        @focus="onImageRowClicked(item)"
                        autocomplete="off"
                      ></b-form-input>
                    </template>

                    <!-- Custom template for Oedema column (editable) -->
                    <template v-slot:item.oedema="{ item }">
                      <b-form-input
                        v-model="item.oedema"
                        outlined
                        class="text-center"
                        @focus="onImageRowClicked(item)"
                        autocomplete="off"
                      ></b-form-input>
                    </template>

                    <!-- Add a column for the delete button -->
                    <template v-slot:item.action="{ item }">
                      <span
                        class="delete-button"
                        @click="confirmTableDelete(item)"
                        >X</span
                      >
                    </template>
                  </v-data-table>

                  <!-- For showing the preview window when an image in the table is clicked -->
                  <!-- It should restore the position or view on the table when the modal is closed -->
                  <b-modal
                    id="modal-image-preview"
                    size="xl"
                    no-scroll
                    @hidden="restoreScrollPosition"
                  >
                    <template #modal-footer="{}">
                      <b-img
                        :src="previewImageSrc"
                        alt="Image Preview"
                        fluid-grow
                      ></b-img>
                    </template>
                  </b-modal>
                </div>
              </div>
            </b-card-text>
          </b-tab>

          <!-- EASI score -->
          <b-tab title="EASI">
            <b-card-text>
              <h3>Eczema Area and Severity Index (EASI) case report form</h3>

              <b>EASI calculation</b>
              <p></p>
              <div v-if="patient_age_at_visit != null">
                <b>
                  Patient was {{ patient_age_at_visit }} years old at this
                  visit.
                </b>
              </div>
              <div v-else>
                <b>Patient age at this visit is not available.</b>
              </div>
              <!-- https://stackoverflow.com/questions/60208815/vuejs-dynamic-b-table-with-editable-fields-and-two-way-databinding -->
              <div>
                <b-table
                  hover
                  bordered
                  :items="easi_items"
                  :fields="easi_fields"
                  fixed
                >
                  <template
                    v-for="field in editableEASIFields"
                    v-slot:[`cell(${field.key})`]="{ item }"
                  >
                    <div :key="field.key">
                      <b-input
                        type="number"
                        min="0"
                        :max="findEASImaxvalue(field)"
                        :step="findEASIstepvalue(field)"
                        v-model="item[field.key]"
                        required
                        aria-required="true"
                        :state="checkEASItablevalue(field, item[field.key])"
                      ></b-input>
                    </div>
                  </template>
                </b-table>
              </div>

              <b>EASI total: {{ easiComputedTotalValue }}</b>

              <div class="add-button-container">
                <b-button variant="primary" @click="onSave('EASI')"
                  >Save</b-button
                >
              </div>

              <p></p>
              <p></p>
              <p><u>Explanation of EASI calculation:</u></p>
              <p></p>

              <p>
                <strong>Area of Involvement:</strong> Each body region has
                potentially 100% involvement. Score
                <strong>0 to 6</strong> based on the following table:
              </p>
              <b-table
                hover
                bordered
                :items="easi_area_involvment_table"
                fixed
              ></b-table>

              <b>Severity of Signs</b>
              <p>
                Grade the severity of each sign on a scale of
                <strong>0 to 3</strong>
              </p>
              <ul>
                <li>
                  Take an average of the severity across the involved area
                </li>
                <li>
                  Half points (1.5 and 2.5) may be used. 0.5 is not permitted -
                  if a sign is present, it should be at least mild (1)
                </li>
              </ul>
              <div style="width: 50%">
                <b-table
                  hover
                  bordered
                  :items="easi_severity_scale_table"
                  fixed
                ></b-table>
              </div>

              <b>Multipliers</b>
              <p>Use the multipliers for the different age groups:</p>
              <div style="width: 50%">
                <b-table
                  hover
                  bordered
                  :items="easi_multiplier_table"
                  fixed
                ></b-table>
              </div>
            </b-card-text>
          </b-tab>

          <!-- BSA -->
          <b-tab title="BSA">
            <b-card-text>
              <h3>Body Surface Area</h3>

              <div>
                <p>Extent</p>
                <Mannequin
                  v-on:update="updateMannequin"
                  v-on:update-regions="updateRegionsMannequin"
                  v-on:selected-regions="selectedRegionsMannequin"
                  v-on:toggle-no-region="toggleNoRegionMannequin"
                  :mannequin_selected_regions="
                    mannequin_selected_regions_reload
                  "
                  :mannequin_selected_regions_backup="
                    mannequin_selected_regions_backup_reload
                  "
                  :mannequin_no_region="mannequin_no_region_reload"
                />

                <!-- <div v-if="patient_age_at_visit != null">
                  <p>
                    Patient was {{ patient_age_at_visit }} years old at this
                    visit.
                  </p>
                </div>
                <div v-else>
                  <p>Patient age at this visit is not available.</p>
                </div>
                <b>{{ extentText }}</b>
                <p></p>
                <p></p> -->
              </div>

              <!-- <b-form-group label="Representative site">
                <b-form-select
                  v-model="scoradRepresentativeSiteValue"
                  :options="scoradRepresentativeSiteOptions"
                  aria-required="true"
                  class="b-form-select-25pcnt"
                ></b-form-select>
              </b-form-group> -->

              <b-form-group
                label="Body surface area by body site"
                class="b-form-select-50pcnt"
              >
                <b-form-row>
                  <b-col>Head &amp; neck (max 100%): </b-col>
                  <b-col
                    ><b-form-input
                      ref="bsaHeadNeck"
                      type="number"
                      min="0"
                      max="100"
                      step="0.1"
                      v-model="scoradBSAHeadNeck"
                      placeholder="0.0"
                      class="b-form-select-50pcnt"
                      autocomplete="off"
                  /></b-col>
                </b-form-row>

                <b-form-row>
                  <b-col>Trunk (max 100%): </b-col>
                  <b-col
                    ><b-form-input
                      ref="bsaTrunk"
                      type="number"
                      min="0"
                      max="100"
                      step="0.1"
                      v-model="scoradBSATrunk"
                      placeholder="0.0"
                      class="b-form-select-50pcnt"
                      autocomplete="off"
                  /></b-col>
                </b-form-row>

                <b-form-row>
                  <b-col>Upper limbs (max 100%): </b-col>
                  <b-col
                    ><b-form-input
                      ref="bsaUpperLimbs"
                      type="number"
                      min="0"
                      max="100"
                      step="0.1"
                      v-model="scoradBSAUpperLimbs"
                      placeholder="0.0"
                      class="b-form-select-50pcnt"
                      autocomplete="off"
                  /></b-col>
                </b-form-row>

                <b-form-row>
                  <b-col>Lower Limbs (max 100%): </b-col>
                  <b-col
                    ><b-form-input
                      ref="bsaLowerLimbs"
                      type="number"
                      min="0"
                      max="100"
                      step="0.1"
                      v-model="scoradBSALowerLimbs"
                      placeholder="0.0"
                      class="b-form-select-50pcnt"
                      autocomplete="off"
                  /></b-col>
                </b-form-row>
              </b-form-group>

              <!-- <p>B. Intensity (Means of calculation)</p> -->
              <!-- <div style="padding: 1rem">
                <b-form-group label="Erythema">
                  <div class="options-container">
                    <div
                      v-for="(option, index) in scoradOptionsStr"
                      :key="index"
                      class="option-box"
                      :class="{
                        selected: localSCORADSelectedOption[0] == index,
                      }"
                      @click="selectSCORADOption(index, 0)"
                      @keydown.enter="selectSCORADOption(index, 0)"
                    >
                      {{ option }}
                    </div>
                  </div>
                </b-form-group>
                <b-form-group label="Edema/Papulation">
                  <div class="options-container">
                    <div
                      v-for="(option, index) in scoradOptionsStr"
                      :key="index"
                      class="option-box"
                      :class="{
                        selected: localSCORADSelectedOption[1] == index,
                      }"
                      @click="selectSCORADOption(index, 1)"
                      @keydown.enter="selectSCORADOption(index, 1)"
                    >
                      {{ option }}
                    </div>
                  </div>
                </b-form-group>
                <b-form-group label="Oozing/Crust">
                  <div class="options-container">
                    <div
                      v-for="(option, index) in scoradOptionsStr"
                      :key="index"
                      class="option-box"
                      :class="{
                        selected: localSCORADSelectedOption[2] == index,
                      }"
                      @click="selectSCORADOption(index, 2)"
                      @keydown.enter="selectSCORADOption(index, 2)"
                    >
                      {{ option }}
                    </div>
                  </div>
                </b-form-group>
                <b-form-group label="Excoriation">
                  <div class="options-container">
                    <div
                      v-for="(option, index) in scoradOptionsStr"
                      :key="index"
                      class="option-box"
                      :class="{
                        selected: localSCORADSelectedOption[3] == index,
                      }"
                      @click="selectSCORADOption(index, 3)"
                      @keydown.enter="selectSCORADOption(index, 3)"
                    >
                      {{ option }}
                    </div>
                  </div>
                </b-form-group>
                <b-form-group label="Lichenification">
                  <div class="options-container">
                    <div
                      v-for="(option, index) in scoradOptionsStr"
                      :key="index"
                      class="option-box"
                      :class="{
                        selected: localSCORADSelectedOption[4] == index,
                      }"
                      @click="selectSCORADOption(index, 4)"
                      @keydown.enter="selectSCORADOption(index, 4)"
                    >
                      {{ option }}
                    </div>
                  </div>
                </b-form-group>
                <b-form-group label="Dryness (Evaluated on uninvolved areas)">
                  <div class="options-container">
                    <div
                      v-for="(option, index) in scoradOptionsStr"
                      :key="index"
                      class="option-box"
                      :class="{
                        selected: localSCORADSelectedOption[5] == index,
                      }"
                      @click="selectSCORADOption(index, 5)"
                      @keydown.enter="selectSCORADOption(index, 5)"
                    >
                      {{ option }}
                    </div>
                  </div>
                </b-form-group>
                <em>Intensity total: {{ scoradIntensitiesTotalValue }} </em>
              </div> -->

              <!-- <p></p>
              <p>
                C. Subjective Symptoms, pruritus + sleep loss (Average of the
                last 3 days or nights. Scale of 0 to 10)
              </p>
              <div style="padding: 1rem">
                <b-form-group label="Pruritus">
                  <b-form-input
                    v-model="scoradPruritusValue"
                    type="number"
                    min="0"
                    max="10"
                    oninput="validity.valid||(value='');"
                    class="b-form-select-25pcnt"
                    aria-required="true"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="Sleep loss">
                  <b-form-input
                    v-model="scoradSleepValue"
                    type="number"
                    min="0"
                    max="10"
                    oninput="validity.valid||(value='');"
                    class="b-form-select-25pcnt"
                    aria-required="true"
                  ></b-form-input>
                </b-form-group>

                <em
                  >Subjective symptoms total: {{ scoradSymptomsTotalValue }}</em
                >
              </div>

              <p></p>
              <b>SCORAD Total </b> (A/5 + 7B/2 + C):
              <b>{{ scoradComputedTotalValue }}</b>

              <p></p>
              <p>
                SCORAD Ref: Severity scoring of atopic dermatitis: the SCORAD
                index. Consensus Report of the European Task Force on Atopic
                Dermatitis. Dermatology. 1993;186(1):23-31.
              </p> -->
            </b-card-text>

            <div class="add-button-container">
              <b-button variant="primary" @click="onSave('BSA')">Save</b-button>
            </div>
          </b-tab>

          <!-- POEM score -->
          <b-tab title="POEM">
            <b-card-text>
              <h3>
                Patient-Orientated Eczema Measure (POEM) for self or proxy
                completion
              </h3>

              <p>Completed by</p>
              <b-form-select
                v-model="completedByValue"
                :options="completedByOptions"
                required
                aria-required="true"
                class="b-form-select-25pcnt"
              ></b-form-select>

              <p></p>
              <p>
                1. Over the last week, on how many days has your / your child’s
                skin been itchy because of the eczema?
              </p>
              <div class="options-container">
                <div
                  v-for="(option, index) in poemOptionsStr"
                  :key="index"
                  class="option-box"
                  :class="{ selected: localPOEMSelectedOption[0] === index }"
                  @click="selectPOEMOption(index, 0)"
                  @keydown.enter="selectPOEMOption(index, 0)"
                >
                  <!-- tabindex to allow buttons to be navigable with keyboard -->
                  {{ option }}
                </div>
              </div>

              <p></p>
              <p>
                2. Over the last week, on how many days has your / your child’s
                sleep been disturbed because of the eczema?
              </p>
              <div class="options-container">
                <div
                  v-for="(option, index) in poemOptionsStr"
                  :key="index"
                  class="option-box"
                  :class="{ selected: localPOEMSelectedOption[1] === index }"
                  @click="selectPOEMOption(index, 1)"
                  @keydown.enter="selectPOEMOption(index, 1)"
                >
                  <!-- tabindex to allow buttons to be navigable with keyboard -->
                  {{ option }}
                </div>
              </div>

              <p></p>
              <p>
                3. Over the last week, on how many days has your / your child’s
                skin been bleeding because of the eczema?
              </p>
              <div class="options-container">
                <div
                  v-for="(option, index) in poemOptionsStr"
                  :key="index"
                  class="option-box"
                  :class="{ selected: localPOEMSelectedOption[2] === index }"
                  @click="selectPOEMOption(index, 2)"
                  @keydown.enter="selectPOEMOption(index, 2)"
                >
                  <!-- tabindex to allow buttons to be navigable with keyboard -->
                  {{ option }}
                </div>
              </div>

              <p></p>
              <p>
                4. Over the last week, on how many days has your / your child’s
                skin been weeping / oozing because of the eczema?
              </p>
              <div class="options-container">
                <div
                  v-for="(option, index) in poemOptionsStr"
                  :key="index"
                  class="option-box"
                  :class="{ selected: localPOEMSelectedOption[3] === index }"
                  @click="selectPOEMOption(index, 3)"
                  @keydown.enter="selectPOEMOption(index, 3)"
                >
                  <!-- tabindex to allow buttons to be navigable with keyboard -->
                  {{ option }}
                </div>
              </div>

              <p></p>
              <p>
                5. Over the last week, on how many days has your / your child’s
                skin been cracked because of the eczema?
              </p>
              <div class="options-container">
                <div
                  v-for="(option, index) in poemOptionsStr"
                  :key="index"
                  class="option-box"
                  :class="{ selected: localPOEMSelectedOption[4] === index }"
                  @click="selectPOEMOption(index, 4)"
                  @keydown.enter="selectPOEMOption(index, 4)"
                >
                  <!-- tabindex to allow buttons to be navigable with keyboard -->
                  {{ option }}
                </div>
              </div>

              <p></p>
              <p>
                6. Over the last week, on how many days has your / your child’s
                skin been dry because of the eczema?
              </p>
              <div class="options-container">
                <div
                  v-for="(option, index) in poemOptionsStr"
                  :key="index"
                  class="option-box"
                  :class="{ selected: localPOEMSelectedOption[5] === index }"
                  @click="selectPOEMOption(index, 5)"
                  @keydown.enter="selectPOEMOption(index, 5)"
                >
                  <!-- tabindex to allow buttons to be navigable with keyboard -->
                  {{ option }}
                </div>
              </div>

              <p></p>
              <p>
                7. Over the last week, on how many days has your / your child’s
                skin been flaking because of the eczema?
              </p>
              <div class="options-container">
                <div
                  v-for="(option, index) in poemOptionsStr"
                  :key="index"
                  class="option-box"
                  :class="{ selected: localPOEMSelectedOption[6] === index }"
                  @click="selectPOEMOption(index, 6)"
                  @keydown.enter="selectPOEMOption(index, 6)"
                >
                  <!-- tabindex to allow buttons to be navigable with keyboard -->
                  {{ option }}
                </div>
              </div>
            </b-card-text>

            <b>POEM total: {{ poemComputedTotalValue }} </b> (maximum 28)

            <p></p>
            <p>
              POEM &copy; CR Charman, AJ Venn, HC Williams, December 2004.
              University of Nottingham.
            </p>

            <div class="add-button-container">
              <b-button variant="primary" @click="onSave('POEM')"
                >Save</b-button
              >
            </div>
          </b-tab>
          <!-- IGA -->
          <b-tab title="IGA">
            <b-card-text>
              <h3>Investigator Global Assessment (IGA)</h3>

              <b-form-group label="IGA">
                <b-form-select
                  v-model="igaValue"
                  :options="igaOptions"
                  aria-required="true"
                  class="b-form-select-25pcnt"
                ></b-form-select>
              </b-form-group>
            </b-card-text>
            <div class="add-button-container">
              <b-button variant="primary" @click="onSave('IGA')">Save</b-button>
            </div>

            <p>
              <b>NOTE</b>: refer to the table below for the corresponding IGA
              scores.
            </p>
            <b-table bordered :items="table_IGA_fields"></b-table>
          </b-tab>

          <!-- Clinical Information -->
          <!-- Questions 14 to 17 in the CRF -->
          <b-tab title="Clinical Information">
            <b-card-text>
              <p>1. How long ago did you last apply moisturiser / emollient?</p>
              <b-form-group>
                <b-form-select
                  v-model="clinicalInfoMoisturiserValue"
                  :options="moisturiserOptions"
                  required
                  aria-required="true"
                  class="b-form-select-25pcnt"
                ></b-form-select>
                <div class="pl-4">
                  <b-form-group
                    label="If &lt;4 hours, please specify:"
                    v-if="clinicalInfoMoisturiserValue == '<4 hours'"
                  >
                    <b-form-input
                      v-model="clinicalInfoMoisturiserLessThan4HoursText"
                      type="text"
                      required
                      placeholder="Please specify..."
                      autocomplete="off"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </b-form-group>

              <b-form-group
                label="2. Eczema extensor/flexor presentation"
                v-slot="{ ariaDescribedby }"
                :class="
                  clinicalInfoPresentationExtensoFlexorValue == null
                    ? 'red-text'
                    : ''
                "
              >
                <b-form-radio-group
                  v-model="clinicalInfoPresentationExtensoFlexorValue"
                  :options="YesNoOptions"
                  aria-required="true"
                ></b-form-radio-group>
              </b-form-group>
              <div>
                <b-form-group label="2a. Discoid ">
                  <b-form-radio-group
                    v-model="clinicalInfoPresentationDiscoidValue"
                    :options="YesNoOptions"
                  ></b-form-radio-group>
                  <div class="pl-4">
                    <p></p>
                    <b-form-group
                      label="If Discoid is yes, which image numbers?"
                      v-if="clinicalInfoPresentationDiscoidValue == 'Yes'"
                    >
                      <b-form-input
                        v-model="clinicalInfoPresentationDiscoidText"
                        type="text"
                        placeholder="Please specify..."
                        autocomplete="off"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </b-form-group>
                <b-form-group label="2b. Prurigo nodularis">
                  <b-form-radio-group
                    v-model="clinicalInfoPresentationPrurigoNodularisValue"
                    :options="YesNoOptions"
                  ></b-form-radio-group>
                  <div class="pl-4">
                    <p></p>
                    <b-form-group
                      label="If Prurigo nodularis is yes, which image numbers?"
                      v-if="
                        clinicalInfoPresentationPrurigoNodularisValue == 'Yes'
                      "
                    >
                      <b-form-input
                        v-model="clinicalInfoPresentationPrurigoNodularisText"
                        type="text"
                        placeholder="Please specify..."
                        autocomplete="off"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </b-form-group>
                <b-form-group label="2c. Pompholyx">
                  <b-form-radio-group
                    v-model="clinicalInfoPresentationPompholyxValue"
                    :options="YesNoOptions"
                  ></b-form-radio-group>
                  <div class="pl-4">
                    <p></p>
                    <b-form-group
                      label="If Pompholyx is yes, which image numbers?"
                      v-if="clinicalInfoPresentationPompholyxValue == 'Yes'"
                    >
                      <b-form-input
                        v-model="clinicalInfoPresentationPompholyxText"
                        type="text"
                        placeholder="Please specify..."
                        autocomplete="off"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </b-form-group>
                <b-form-group label="2d. Follicular">
                  <b-form-radio-group
                    v-model="clinicalInfoPresentationFollicularValue"
                    :options="YesNoOptions"
                  ></b-form-radio-group>
                  <div class="pl-4">
                    <p></p>
                    <b-form-group
                      label="If Follicular is yes, which image numbers?"
                      v-if="clinicalInfoPresentationFollicularValue == 'Yes'"
                    >
                      <b-form-input
                        v-model="clinicalInfoPresentationFollicularText"
                        type="text"
                        placeholder="Please specify..."
                        autocomplete="off"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </b-form-group>
                <b-form-group label="2e. Other Comments">
                  <b-form-radio-group
                    v-model="clinicalInfoPresentationCommentsValue"
                    :options="YesNoOptions"
                  ></b-form-radio-group>
                  <div class="pl-4">
                    <p></p>
                    <b-form-group
                      label="If Other Comments is yes, please specify"
                      v-if="clinicalInfoPresentationCommentsValue == 'Yes'"
                    >
                      <b-form-input
                        v-model="clinicalInfoPresentationCommentsText"
                        type="text"
                        placeholder="Please specify..."
                        autocomplete="off"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </b-form-group>
              </div>

              <p></p>
              <b-form-group label="3. Eczema Acute - Infected">
                <b-form-select
                  v-model="clinicalInfoAcuteInfectedValue"
                  required
                  :options="eczemaAcuteInfectedOptions"
                  class="b-form-select-25pcnt"
                ></b-form-select>
                <div class="pl-4">
                  <b-form-group
                    label="Is yes, please specify:"
                    v-if="clinicalInfoAcuteInfectedValue == 'Yes'"
                  >
                    <b-form-group label="3a. bacterial">
                      <b-form-select
                        v-model="clinicalInfoAcuteBacterialValue"
                        required
                        :options="eczemaAcuteInfectedYesOptions"
                        class="b-form-select-25pcnt"
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group label="3b. viral">
                      <b-form-select
                        v-model="clinicalInfoAcuteVirualValue"
                        required
                        :options="eczemaAcuteInfectedYesOptions"
                        class="b-form-select-25pcnt"
                      ></b-form-select>
                    </b-form-group>
                  </b-form-group>
                </div>
              </b-form-group>

              <b-form-group
                label="4. Any areas which patient does NOT wish to have photographed"
                :class="
                  clinicalInfoPatientAreasNotPhotographedValue == null
                    ? 'red-text'
                    : ''
                "
              >
                <b-form-radio-group
                  v-model="clinicalInfoPatientAreasNotPhotographedValue"
                  :options="YesNoOptions"
                ></b-form-radio-group>
                <div class="pl-4">
                  <p></p>
                  <b-form-group
                    label="If yes, please specify (e.g. eyes, face, etc.)"
                    v-if="clinicalInfoPatientAreasNotPhotographedValue == 'Yes'"
                  >
                    <b-form-input
                      v-model="clinicalInfoPatientAreasNotPhotographedText"
                      required
                      type="text"
                      placeholder="Please specify..."
                      autocomplete="off"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </b-form-group>
            </b-card-text>

            <div class="add-button-container">
              <b-button
                variant="primary"
                @click="onSave('Clinical Information')"
                >Save</b-button
              >
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
      <!-- end of tabs -->
    </div>
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/vue/2.6.10/vue.js"></script>
<script src="https://unpkg.com/bootstrap-vue@2.4.1/dist/bootstrap-vue.min.js"></script>

<script>
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import PictureInput from "@/view/pages/PictureInput.vue";
import Mannequin from "@/view/pages/Mannequin.vue";

export default {
  name: "ImageUpload",
  components: { PictureInput, Mannequin },
  props: {
    uuid: {
      type: String,
      // required: true,
    },
    allImages: {
      type: Object,
      required: false,
      default: () => ({ images: null }),
    },
    region: {
      type: String,
      // required: true,
    },
  },
  // emits: ['update-answers', 'update-regions', 'all-questions-answered', 'genitals-selected'],
  data() {
    return {
      local_images: [], // this.allImages.images,
      local_images_keys: [],

      patient_birthday: null,
      patient_clinical_reference: null,
      visit_date: null,
      image_data: null,
      first_time_loading: true,

      new_items: [],
      new_headers: [
        { text: "Image", value: "imgsrc" },
        { text: "Quality", value: "imageQualityValue" },
        { text: "Type", value: "imageTypeValue" },
        { text: "Body site", value: "imageLocationValue" },
        { text: "Rep. site", value: "imageRepresentativeSiteValue" },
        { text: "Android", value: "imageAndroidValue" },
        { text: "Cracking", value: "cracking" },
        { text: "Dryness", value: "dryness" },
        { text: "Erythema", value: "erythema" },
        { text: "Excoriation", value: "excoriation" },
        { text: "Exudation", value: "exudation" },
        { text: "Lichenification", value: "lichenification" },
        { text: "Oedema", value: "oedema" },
        { text: "Delete", value: "action" },
      ],

      scrollPosition: null, // Variable to store scroll position, i.e. last position on the table

      YesNoOptions: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" },
      ],

      // General record completion
      recordCompletionValue: null,
      recordCompletionOptions: [
        { value: "in_progress", text: "In Progress" },
        { value: "completed", text: "Completed" },
      ],
      lightingValue: null,
      lightingOptions: [
        { value: "daylight", text: "daylight" },
        { value: "mixed", text: "mixed" },
        { value: "artificial light", text: "artificial light" },
        { value: "Missing", text: "Missing" },
      ],
      numScorerValue: null,
      patient_age_at_visit: null,

      // severity scores
      crackingScore: null,
      drynessScore: null,
      erythemaScore: null,
      excoriationScore: null,
      exudationScore: null,
      lichenificationScore: null,
      oedemaScore: null,
      severityOptions: [
        { value: 0, text: "0" },
        { value: 1, text: "1" },
        { value: 2, text: "2" },
        { value: 3, text: "3" },
      ],
      imageQualityValue: null,
      imageQualityOptions: [
        { value: "high", text: "high" },
        { value: "normal", text: "normal" },
        { value: "low", text: "low" },
      ],
      imageTypeValue: null,
      imageTypeOptions: [
        { value: "close_up", text: "Close up" },
        { value: "overview", text: "Overview" },
      ],
      imageLocationValue: null,
      imageRepresentativeSiteValue: null,
      imageAndroidValue: null,
      imageLocationOptions: [
        { value: "Head & Neck", text: "Head & Neck" },
        { value: "Trunk", text: "Trunk" },
        { value: "Arm", text: "Arm" },
        { value: "Leg", text: "Leg" },
      ],
      imageFileExtension: null,

      // list of records added
      all_records: [],
      severity_items: [],
      is_editing_image_severity: false,
      editing_image_idx: null,
      editing_severity_idx: null,
      editing_uuid: null, // uuid of the media record.

      // EASI score
      easi_area_involvment_table: [
        {
          "% involvement": "Region score",
          "0%": 0,
          "1-9%": 1,
          "10-29%": 2,
          "30-49%": 3,
          "50-69%": 4,
          "70-89%": 5,
          "90-100%": 6,
        },
      ],
      easi_severity_scale_table: [
        {
          Scale: "0",
          Description: "None",
        },
        {
          Scale: "1",
          Description: "Mild",
        },
        {
          Scale: "2",
          Description: "Moderate",
        },
        {
          Scale: "3",
          Description: "Severe",
        },
      ],
      easi_multiplier_table: [
        {
          "Body region": "Head/neck",
          "below 8": 0.2,
          "8 and above": 0.1,
        },
        {
          "Body region": "Trunk",
          "below 8": 0.3,
          "8 and above": 0.3,
        },
        {
          "Body region": "Upper extremities",
          "below 8": 0.2,
          "8 and above": 0.2,
        },
        {
          "Body region": "Lower extremities",
          "below 8": 0.3,
          "8 and above": 0.4,
        },
      ],
      easi_items: [
        {
          body_region: "Head/neck",
          erythema: null,
          edema: null,
          excoriation: null,
          lichenification: null,
          region_score: null,
          multiplier: null,
          total: null,
        },
        {
          body_region: "Trunk",
          erythema: null,
          edema: null,
          excoriation: null,
          lichenification: null,
          region_score: null,
          multiplier: null,
          total: null,
        },
        {
          body_region: "Upper extremities",
          erythema: null,
          edema: null,
          excoriation: null,
          lichenification: null,
          region_score: null,
          multiplier: null,
          total: null,
        },
        {
          body_region: "Lower extremities",
          erythema: null,
          edema: null,
          excoriation: null,
          lichenification: null,
          region_score: null,
          multiplier: null,
          total: null,
        },
      ],
      easi_fields: [
        { key: "body_region", label: "Body region" },
        { key: "erythema", label: "Erythema (0-3)", editable: true },
        { key: "edema", label: "Edema/Papulation (0-3)", editable: true },
        { key: "excoriation", label: "Excoriation (0-3)", editable: true },
        {
          key: "lichenification",
          label: "Lichenification (0-3)",
          editable: true,
        },
        { key: "region_score", label: "Region score (0-6)", editable: true },
        { key: "multiplier", label: "Multiplier", editable: false },
        { key: "total", label: "Score per body region", editable: false },
      ],

      // SCORAD score
      showHelpText: false,
      localAnswers: {},
      mannequin_update: null,
      mannequin_regions: null,
      mannequin_selected_regions: null,
      mannequin_selected_regions_reload: null, // for reloading/refreshing, so that the watch function is triggered only once.
      // note: mannequin_regions is set to [] when the No Region Affected button is selected.
      // The backup restores the original regions, when the button is unselected.
      mannequin_selected_regions_backup: null,
      mannequin_selected_regions_backup_reload: null, // for reloading/refreshing, so that the watch function is triggered only once.
      mannequin_no_region: null,
      mannequin_no_region_reload: null, // for reloading/refreshing, so that the watch function is triggered only once.
      mannequin_area_score_below_2: {
        front: [
          2, // scalp
          6.5, // face
          2, // neck
          2, // right shoulder
          0.5, // right elbow
          2, // right forearm
          0.5, // right hand
          2, // left shoulder
          0.5, // left elbow
          2, // left forearm
          0.5, // left hand
          7, // body_up
          5, // body_down
          5, // belly
          1, // genitals
          2, // right thigh
          1, // right knee
          2, // right calf
          1, // right foot
          2, // left thigh
          1, // left knee
          2, // left calf
          1, // left foot
        ],
        back: [
          6, // scalp
          2.5, // head
          2, // right shoulder
          0.5, // right elbow
          2, // right forearm
          0.5, // right hand
          2, // left shoulder
          0.5, // left elbow
          2, // left forearm
          0.5, // left hand
          6, // body_up
          4, // body_down
          4, // lower back
          4, // bum
          2, // right thigh
          1, // right knee
          2, // right calf
          1, // right foot
          2, // left thigh
          1, // left knee
          2, // left calf
          1, // left foot
        ],
      },
      mannequin_area_score_2_and_above: {
        front: [
          1, // scalp
          3.5, // face
          2, // neck
          2, // right shoulder
          0.5, // right elbow
          2, // right forearm
          0.5, // right hand
          2, // left shoulder
          0.5, // left elbow
          2, // left forearm
          0.5, // left hand
          7, // body_up
          5, // body_down
          5, // belly
          1, // genitals
          3, // right thigh
          2, // right knee
          3, // right calf
          1, // right foot
          3, // left thigh
          2, // left knee
          3, // left calf
          1, // left foot
        ],
        back: [
          3, // scalp
          1.5, // head
          2, // right shoulder
          0.5, // right elbow
          2, // right forearm
          0.5, // right hand
          2, // left shoulder
          0.5, // left elbow
          2, // left forearm
          0.5, // left hand
          6, // body_up
          4, // body_down
          4, // lower back
          4, // bum
          3, // right thigh
          2, // right knee
          3, // right calf
          1, // right foot
          3, // left thigh
          2, // left knee
          3, // left calf
          1, // left foot
        ],
      },
      // mannequin_genitals_selected: null,

      extentValue: null,
      extentText: "",
      scoradErythemaIntensityValue: null,
      scoradEdemaIntensityValue: null,
      scoradOozingIntensityValue: null,
      scoradExcoradIntensityValue: null,
      scoradLichenificationIntensityValue: null,
      scoradDrynessIntensityValue: null,
      scoradIntensityOptions: [
        { value: 0, text: "Absence" },
        { value: 1, text: "Mild" },
        { value: 2, text: "Moderate" },
        { value: 3, text: "Severe" },
      ],
      scoradOptionsStr: ["Absence", "Mild", "Moderate", "Severe"],
      // scoradIntensityTotalValue: scoradErythemaIntensityValue + scoradEdemaIntensityValue + scoradOozingIntensityValue + scoradExcoradIntensityValue + scoradLichenificationIntensityValue + scoradDrynessIntensityValue,
      // scoradRepresentativeSiteValue: null,
      // scoradRepresentativeSiteOptions: [
      //   { value: "Head & neck front", text: "Head & neck front" },
      //   { value: "Head & neck back", text: "Head & neck back" },
      //   { value: "Trunk front", text: "Trunk front" },
      //   { value: "Trunk back", text: "Trunk back" },
      //   { value: "Upper limb left", text: "Upper limb left" },
      //   { value: "Upper limb right", text: "Upper limb right" },
      //   { value: "Lower limb left", text: "Lower limb left" },
      //   { value: "Lower limb right", text: "Lower limb right" },
      // ],
      scoradBSAHeadNeck: null,
      scoradBSATrunk: null,
      scoradBSAUpperLimbs: null,
      scoradBSALowerLimbs: null,
      scoradPruritusValue: null,
      scoradSleepValue: null,
      localSCORADSelectedOption: new Array(6).fill(10),

      // POEM score
      completedByValue: null,
      completedByOptions: [
        { value: "Patient", text: "Patient" },
        {
          value: "Patient & Parent/Guardian",
          text: "Patient & Parent/Guardian",
        },
        { value: "Parent/Guardian", text: "Parent/Guardian" },
        { value: "Missing", text: "Missing" },
      ],
      q1ItchyEczemaValue: null,
      q2SleepDisturbedValue: null,
      q3BleedingValue: null,
      q4WeepingValue: null,
      q5CrackedValue: null,
      q6DryValue: null,
      q7FlakingValue: null,
      poemOptionsStr: [
        "No days",
        "1-2 days",
        "3-4 days",
        "5-6 days",
        "Every day",
      ],
      localPOEMSelectedOption: new Array(7).fill(null),

      // IGA
      igaValue: null,
      igaOptions: [
        { value: "Clear", text: "Clear" },
        { value: "Almost clear", text: "Almost clear" },
        { value: "Mild", text: "Mild" },
        { value: "Moderate", text: "Moderate" },
        { value: "Severe", text: "Severe" },
      ],
      table_IGA_fields: [
        {
          score: "Clear",
          morphological_description:
            "No imflammatory signs of atopic dermatitis (no erythema, no induration/papulation, no lichenification, no oozing/crusting). Post-inflammatory hyperpigmentation and/or hypopigmentation may be present.",
        },
        {
          score: "Almost clear",
          morphological_description:
            "Barely perceptible erythema, barely perceptible induration/papulation, and/or lichenification. No oozing or crusting.",
        },
        {
          score: "Mild",
          morphological_description:
            "Slight but definite erythema(pink), slight but definite induration/papulation, and/or slight but definite lichenification. No oozing or crusting.",
        },
        {
          score: "Moderate",
          morphological_description:
            "Clearly perceptible erythema (dull red), clearly perceptible induration/papulation, and/or clearly perceptible lichenification. No oozing or crusting may be present.",
        },
        {
          score: "Severe",
          morphological_description:
            "Marked erythema (deep or bright red), marked induration/papulation, and/or marked lichenification. Disease is widespread in extent. Oozing and crusting may be present.",
        },
      ],

      // Clinical Information
      moisturiserOptions: [
        { value: ">4 hours", text: ">4 hours" },
        { value: "<4 hours", text: "<4 hours" },
        { value: "Missing", text: "Missing" },
      ],
      clinicalInfoMoisturiserValue: null,
      clinicalInfoMoisturiserLessThan4HoursText: null,
      clinicalInfoPresentationExtensoFlexorValue: null,
      clinicalInfoPresentationDiscoidValue: null,
      clinicalInfoPresentationDiscoidText: null,
      clinicalInfoPresentationPrurigoNodularisValue: null,
      clinicalInfoPresentationPrurigoNodularisText: null,
      clinicalInfoPresentationPompholyxValue: null,
      clinicalInfoPresentationPompholyxText: null,
      clinicalInfoPresentationFollicularValue: null,
      clinicalInfoPresentationFollicularText: null,
      clinicalInfoPresentationCommentsValue: null,
      clinicalInfoPresentationCommentsText: null,
      clinicalInfoAcuteInfectedValue: null,
      eczemaAcuteInfectedOptions: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" },
        { value: "Not Known", text: "Not known" },
      ],
      clinicalInfoAcuteBacterialValue: null,
      clinicalInfoAcuteVirualValue: null,
      eczemaAcuteInfectedYesOptions: [
        { value: "Yes", text: "Yes" },
        { value: "Suspected", text: "Suspected" },
        { value: "No", text: "No" },
        { value: "Not Known", text: "Not known" },
      ],
      clinicalInfoPatientAreasNotPhotographedValue: null,
      clinicalInfoPatientAreasNotPhotographedText: null,
    };
  },
  computed: {
    editableEASIFields() {
      return this.easi_fields.filter((field) => field.editable);
    },
    easiComputedTotalValue() {
      // for each region in this.easi_item, compute the sum of erythema, edema, excoriation, and lichenification
      let region_total = 0;
      for (let i = 0; i < this.easi_items.length; i++) {
        region_total =
          parseFloat(this.easi_items[i].erythema) +
          parseFloat(this.easi_items[i].edema) +
          parseFloat(this.easi_items[i].excoriation) +
          parseFloat(this.easi_items[i].lichenification);
        this.easi_items[i].total =
          Math.round(
            region_total *
              parseFloat(this.easi_items[i].region_score) *
              parseFloat(this.easi_items[i].multiplier) *
              100
          ) / 100; // round to 2 decimal places
      }
      let easi_overall_total = 0;
      for (let i = 0; i < this.easi_items.length; i++) {
        easi_overall_total += parseFloat(this.easi_items[i].total);
      }

      return Math.round(easi_overall_total * 100) / 100; // round to 2 decimal places
    },
    scoradIntensitiesTotalValue() {
      // sum of all scorad intensities
      const erythema = parseFloat(this.scoradErythemaIntensityValue);
      const edema = parseFloat(this.scoradEdemaIntensityValue);
      const oozing = parseFloat(this.scoradOozingIntensityValue);
      const excoriation = parseFloat(this.scoradExcoradIntensityValue);
      const lichenification = parseFloat(
        this.scoradLichenificationIntensityValue
      );
      const dryness = parseFloat(this.scoradDrynessIntensityValue);
      return (
        erythema + edema + oozing + excoriation + lichenification + dryness
      );
    },
    scoradSymptomsTotalValue() {
      // sum of pruritus and sleep loss as numbers
      const pruritus = parseFloat(this.scoradPruritusValue);
      const sleep = parseFloat(this.scoradSleepValue);
      return pruritus + sleep;
    },
    scoradComputedTotalValue() {
      // SCORAD Total (A/5 + 7B/2 + C)
      const extent = parseFloat(this.extentValue);
      const intensity = parseFloat(this.scoradIntensitiesTotalValue);
      const symptoms = parseFloat(this.scoradSymptomsTotalValue);
      return extent / 5 + (7 * intensity) / 2 + symptoms;
    },
    poemComputedTotalValue() {
      // POEM total (maximum 28)
      const q1 = parseInt(this.q1ItchyEczemaValue);
      const q2 = parseInt(this.q2SleepDisturbedValue);
      const q3 = parseInt(this.q3BleedingValue);
      const q4 = parseInt(this.q4WeepingValue);
      const q5 = parseInt(this.q5CrackedValue);
      const q6 = parseInt(this.q6DryValue);
      const q7 = parseInt(this.q7FlakingValue);
      return q1 + q2 + q3 + q4 + q5 + q6 + q7;
    },
  },
  watch: {
    $route() {
      this.refresh();
    },
    // watch for changes in the new_items array
    new_items: {
      handler: function (newItems) {
        // Send updated data to the backend
        this.sendDataToBackend(newItems);
        // Update fields if the row is selected
        if (this.is_editing_image_severity) {
          this.updateFields(newItems);
        }
      },
      deep: true, // watch changes deeply within the array
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Visit Data" }]);
    this.refresh();
  },
  methods: {
    async refresh() {
      if (this.first_time_loading) {
        // get the meta_data of the questionnaire to fill in the details if it was completed previously
        ApiService.get(
          "/questionnaires/answers/patient/" +
            this.$route.params.id +
            "/" +
            this.$route.params.uuid
        )
          .then(({ data }) => {
            const meta_data = data.data.meta_data;
            if (meta_data) {
              // Images & Severity Scores
              this.recordCompletionValue = meta_data.recordCompletion;
              this.lightingValue = meta_data.lighting;
              this.numScorerValue = meta_data.numScorer;
              // EASI
              this.easi_items = meta_data.easi_items;
              // SCORAD
              this.mannequin_no_region = meta_data.mannequin_no_region;
              this.mannequin_no_region_reload = meta_data.mannequin_no_region;
              if (this.mannequin_no_region_reload) {
                this.mannequin_selected_regions = {};
                this.mannequin_selected_regions_reload = {};
              } else {
                this.mannequin_selected_regions =
                  meta_data.mannequin_selected_regions;
                this.mannequin_selected_regions_reload =
                  meta_data.mannequin_selected_regions;
              }

              this.mannequin_selected_regions_backup =
                meta_data.mannequin_selected_regions_backup;
              this.mannequin_selected_regions_backup_reload =
                meta_data.mannequin_selected_regions_backup;

              this.extentValue = meta_data.extent;
              this.scoradErythemaIntensityValue =
                meta_data.scoradErythemaIntensity;
              this.scoradEdemaIntensityValue = meta_data.scoradEdemaIntensity;
              this.scoradOozingIntensityValue = meta_data.scoradOozingIntensity;
              this.scoradExcoradIntensityValue =
                meta_data.scoradExcoradIntensity;
              this.scoradLichenificationIntensityValue =
                meta_data.scoradLichenificationIntensity;
              this.scoradDrynessIntensityValue =
                meta_data.scoradDrynessIntensity;
              // this.scoradRepresentativeSiteValue =
              //   meta_data.scoradRepresentativeSiteValue;
              this.scoradBSAHeadNeck = meta_data.scoradBSAHeadNeck;
              this.scoradBSATrunk = meta_data.scoradBSATrunk;
              this.scoradBSAUpperLimbs = meta_data.scoradBSAUpperLimbs;
              this.scoradBSALowerLimbs = meta_data.scoradBSALowerLimbs;
              this.scoradPruritusValue = meta_data.scoradPruritus;
              this.scoradSleepValue = meta_data.scoradSleep;
              this.localSCORADSelectedOption = meta_data.localSCORADSelected;
              // POEM
              this.completedByValue = meta_data.completedByValue;
              this.q1ItchyEczemaValue = meta_data.q1ItchyEczema;
              this.q2SleepDisturbedValue = meta_data.q2SleepDisturbed;
              this.q3BleedingValue = meta_data.q3Bleeding;
              this.q4WeepingValue = meta_data.q4Weeping;
              this.q5CrackedValue = meta_data.q5Cracked;
              this.q6DryValue = meta_data.q6Dry;
              this.q7FlakingValue = meta_data.q7Flaking;
              this.localPOEMSelectedOption = meta_data.localPOEMSelected;
              // IGA
              this.igaValue = meta_data.igaValue;
              // Clinical Information
              this.clinicalInfoMoisturiserValue =
                meta_data.clinicalInfoMoisturiser;
              this.clinicalInfoMoisturiserLessThan4HoursText =
                meta_data.clinicalInfoMoisturiserLessThan4Hours;
              this.clinicalInfoPresentationExtensoFlexorValue =
                meta_data.clinicalInfoPresentationExtensoFlexor;
              this.clinicalInfoPresentationDiscoidValue =
                meta_data.clinicalInfoPresentationDiscoid;
              this.clinicalInfoPresentationDiscoidText =
                meta_data.clinicalInfoPresentationDiscoidText;
              this.clinicalInfoPresentationPrurigoNodularisValue =
                meta_data.clinicalInfoPresentationPrurigoNodularis;
              this.clinicalInfoPresentationPrurigoNodularisText =
                meta_data.clinicalInfoPresentationPrurigoNodularisText;
              this.clinicalInfoPresentationPompholyxValue =
                meta_data.clinicalInfoPresentationPompholyx;
              this.clinicalInfoPresentationPompholyxText =
                meta_data.clinicalInfoPresentationPompholyxText;
              this.clinicalInfoPresentationFollicularValue =
                meta_data.clinicalInfoPresentationFollicular;
              this.clinicalInfoPresentationFollicularText =
                meta_data.clinicalInfoPresentationFollicularText;
              this.clinicalInfoPresentationCommentsValue =
                meta_data.clinicalInfoPresentationComments;
              this.clinicalInfoPresentationCommentsText =
                meta_data.clinicalInfoPresentationCommentsText;
              this.clinicalInfoAcuteInfectedValue =
                meta_data.clinicalInfoAcuteInfected;
              this.clinicalInfoAcuteBacterialValue =
                meta_data.clinicalInfoAcuteBacterial;
              this.clinicalInfoAcuteVirualValue =
                meta_data.clinicalInfoAcuteVirual;
              this.clinicalInfoPatientAreasNotPhotographedValue =
                meta_data.clinicalInfoPatientAreasNotPhotographed;
              this.clinicalInfoPatientAreasNotPhotographedText =
                meta_data.clinicalInfoPatientAreasNotPhotographedText;
            }
          })
          .catch((error) => {
            let error_msg =
              "An error occurred while loading the data the first time. ";
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              error_msg += error.response.data.message;
            }
            alert(error_msg);
          });

        const { data } = await ApiService.get(
          "/media/answers/patient/" + this.$route.params.id
        );
        this.all_records = [];
        this.severity_items = [];
        for (let i = 0; i < data.data.length; i++) {
          if (
            data.data[i].metaData.questionnaireId == this.$route.params.uuid
          ) {
            this.all_records.push(data.data[i]);
            this.severity_items.push(data.data[i].metaData);
            this.local_images_keys.push(data.data[i].image_filename);

            // get images from /images/image/filename
            const filename =
              data.data[i].image_filename + "." + data.data[i].image_extension;
            const extension_name =
              data.data[i].image_extension == "jpg" ? "jpeg" : "png";

            await ApiService.get("/images/image/" + filename)
              .then(({ data }) => {
                const dataUrl =
                  `data:image/` + extension_name + `;base64,${data}`;
                var img = new Image();
                img.src = `${dataUrl}`;
                this.local_images.push(img);
              })
              .catch((error) => {
                alert("Error loading image: " + error);
              }); // download the file
          }
        }

        // get the age of the patient at time of clinic visit.
        // we'll compute this once if it hasn't been computed yet
        if (this.patient_age_at_visit == null) {
          await ApiService.get("/users/patient/" + this.$route.params.id).then(
            ({ data }) => {
              this.patient_birthday = data.data.birthday;
              this.patient_clinical_reference =
                data.data.clinical_reference.toUpperCase();
            }
          );

          // get the questionnaire date
          await ApiService.get(
            "/questionnaires/answers/patient/" +
              this.$route.params.id +
              "/" +
              this.$route.params.uuid
          ).then(({ data }) => {
            this.visit_date = data.data.symptoms_date;
          });

          // compute the age of patients rounded down to the year
          if (this.patient_birthday && this.visit_date) {
            const dob = new Date(this.patient_birthday);
            const visit_date = new Date(this.visit_date);

            if (visit_date >= dob) {
              this.patient_age_at_visit = Math.floor(
                (visit_date - dob) / (1000 * 60 * 60 * 24 * 365.25)
              );
            }
          }

          // set the multiplier table based on age, for the first time
          if (this.patient_age_at_visit != null) {
            if (this.patient_age_at_visit < 8) {
              this.easi_items[0].multiplier = 0.2;
              this.easi_items[1].multiplier = 0.3;
              this.easi_items[2].multiplier = 0.2;
              this.easi_items[3].multiplier = 0.3;
            } else if (this.patient_age_at_visit >= 8) {
              this.easi_items[0].multiplier = 0.1;
              this.easi_items[1].multiplier = 0.3;
              this.easi_items[2].multiplier = 0.2;
              this.easi_items[3].multiplier = 0.4;
            }
          } else {
            this.easi_items[0].multiplier = null;
            this.easi_items[1].multiplier = null;
            this.easi_items[2].multiplier = null;
            this.easi_items[3].multiplier = null;
          }

          // SCORAD
          this.computeSCORADExtentTotalValue();
        }

        this.first_time_loading = false;
      }

      this.updateTable();
    },
    onImageRowClicked(row) {
      // reset the image first,
      // in case the user was in the process of adding a new image but decided to edit an exiting record instead
      this.$refs.pictureInput.removeImage();

      this.is_editing_image_severity = true;
      this.editing_image_idx = row.img_idx;
      this.editing_severity_idx = row.severity_idx;
      this.editing_uuid = row.record_uuid;
      this.crackingScore = row.cracking;
      this.drynessScore = row.dryness;
      this.erythemaScore = row.erythema;
      this.excoriationScore = row.excoriation;
      this.exudationScore = row.exudation;
      this.lichenificationScore = row.lichenification;
      this.oedemaScore = row.oedema;
      this.imageQualityValue = row.imageQualityValue;
      this.imageTypeValue = row.imageTypeValue;
      this.imageLocationValue = row.imageLocationValue;
      this.imageRepresentativeSiteValue = row.imageRepresentativeSiteValue;
      this.imageAndroidValue = row.imageAndroidValue;
      this.imageFileExtension = row.imageFileExtension;

      // load the image on display
      this.image_data = this.local_images[row.img_idx];
      if (this.local_images[row.img_idx]) {
        const editImage = new Image();
        editImage.src = this.local_images[row.img_idx].src; // row.imgsrc;
        this.$refs.pictureInput.drawImage(editImage);
        this.$refs.pictureInput.imageSelected = true;
        this.$refs.pictureInput.imageObject = editImage;
      } else {
        this.$refs.pictureInput.removeImage();
      }
    },
    showImagePreview(previewImageSrc) {
      // Set the previewImageSrc data property to the clicked image source
      this.previewImageSrc = previewImageSrc;
      // Capture the scroll position before opening the modal
      this.captureScrollPosition();
      // show the modal
      this.$bvModal.show("modal-image-preview");
    },
    // Method to capture current scroll position before opening modal
    captureScrollPosition() {
      this.scrollPosition = window.scrollY || window.pageYOffset;
    },
    // Method to restore scroll position after closing modal
    restoreScrollPosition() {
      // Delay the scroll restoration to ensure the modal is fully hidden
      setTimeout(() => {
        window.scrollTo(0, this.scrollPosition);
      }, 100);
    },
    async sendDataToBackend(newItems) {
      // With this approach, we don't need to include @change handlers within the table code.
      // The watch option in Vue.js allows monitoring of changes to data properties, including nested objects and arrays.
      // So, by watching the new_items array for changes, Vue.js will automatically trigger the sendDataToBackend method whenever any item within new_items changes.
      // This approach abstracts away the need for individual @change handlers for each field in the table, making the code cleaner and more maintainable.

      // Code:
      // iterating over each item in the newItems array and preparing metadata for each item.
      // Then, create a record object for each item with the metadata and other necessary properties.
      // All these records are stored in an array recordsToUpdate.
      // Then, use Promise.all to send all records to the backend simultaneously, which can improve performance by making parallel requests.

      try {
        // Prepare an array to hold all the records to be updated
        const recordsToUpdate = [];

        // Loop through each item in the newItems array
        newItems.forEach((item) => {
          // Prepare metadata for the item
          const metaData = {
            crackingScore: item.cracking,
            drynessScore: item.dryness,
            erythemaScore: item.erythema,
            excoriationScore: item.excoriation,
            exudationScore: item.exudation,
            lichenificationScore: item.lichenification,
            oedemaScore: item.oedema,
            imageQualityValue: item.imageQualityValue,
            imageTypeValue: item.imageTypeValue,
            imageLocationValue: item.imageLocationValue,
            imageRepresentativeSiteValue: item.imageRepresentativeSiteValue,
            imageAndroidValue: item.imageAndroidValue,
            questionnaireId: this.$route.params.uuid,
          };

          // Create a record object
          const record = {
            id: item.record_uuid,
            filePath: "/Users/", // Adjust this as needed
            image_extension: item.imageFileExtension,
            metaData: metaData,
          };

          // Add the record to the array
          recordsToUpdate.push(record);
        });

        // Send the records to the backend
        await Promise.all(
          recordsToUpdate.map((record) => {
            return ApiService.put(
              "/media/answers/patient/" +
                this.$route.params.id +
                "/" +
                record.id,
              record
            );
          })
        );
      } catch (error) {
        alert("Error sending data to the backend:", error);
      }
    },
    updateFields(newItems) {
      // Find the edited item in the newItems array
      const editedItem = newItems.find(
        (item) =>
          item.img_idx === this.editing_image_idx &&
          item.severity_idx === this.editing_severity_idx
      );
      // Update fields if the edited item is found
      if (editedItem) {
        this.crackingScore = editedItem.cracking;
        this.drynessScore = editedItem.dryness;
        this.erythemaScore = editedItem.erythema;
        this.excoriationScore = editedItem.excoriation;
        this.exudationScore = editedItem.exudation;
        this.lichenificationScore = editedItem.lichenification;
        this.oedemaScore = editedItem.oedema;
        this.imageQualityValue = editedItem.imageQualityValue;
        this.imageTypeValue = editedItem.imageTypeValue;
        this.imageLocationValue = editedItem.imageLocationValue;
        this.imageRepresentativeSiteValue =
          editedItem.imageRepresentativeSiteValue;
        this.imageAndroidValue = editedItem.imageAndroidValue;
        this.imageFileExtension = editedItem.imageFileExtension;
        // Update image data if available
        this.image_data = this.local_images[editedItem.img_idx];
        // Update image preview if available
        if (this.$refs.pictureInput && this.local_images[editedItem.img_idx]) {
          const editImage = new Image();
          editImage.src = editedItem.imgsrc;
          this.$refs.pictureInput.drawImage(editImage);
          this.$refs.pictureInput.imageSelected = true;
          this.$refs.pictureInput.imageObject = editImage;
        }

        // update the severity items list
        this.severity_items[editedItem.img_idx] = {
          crackingScore: this.crackingScore,
          drynessScore: this.drynessScore,
          erythemaScore: this.erythemaScore,
          excoriationScore: this.excoriationScore,
          exudationScore: this.exudationScore,
          lichenificationScore: this.lichenificationScore,
          oedemaScore: this.oedemaScore,
          imageQualityValue: this.imageQualityValue,
          imageTypeValue: this.imageTypeValue,
          imageLocationValue: this.imageLocationValue,
          imageRepresentativeSiteValue: this.imageRepresentativeSiteValue,
          imageAndroidValue: this.imageAndroidValue,
        };                
      }
    },
    // async onUpdateImageRecord() {
    //   // check if the image has been loaded
    //   if (!this.image_data) {
    //     alert("Please select an image.");
    //     return false;
    //   }
    //   // upload the new image using the same file name as the old one.
    //   if (this.is_changing_image) {
    //     // reset the flag
    //     this.is_changing_image = false;

    //     // upload the image file to the server
    //     const pictureInput = this.$refs.pictureInput;
    //     const selectedImageFile = pictureInput.file;

    //     // check if the file extension has changed. If so, delete the old file.
    //     if (
    //       this.imageFileExtension != selectedImageFile.name.split(".").pop()
    //     ) {
    //       const oldfilename =
    //         this.all_records[this.editing_severity_idx].image_filename +
    //         "." +
    //         this.imageFileExtension;
    //       // delete the old file
    //       try {
    //         await ApiService.delete("/images/image/" + oldfilename);
    //       } catch (error) {
    //         alert("Error deleting the previous image: " + error);
    //       }
    //     }

    //     // get file extension
    //     this.imageFileExtension = selectedImageFile.name.split(".").pop();
    //     const thisfilename =
    //       this.all_records[this.editing_severity_idx].image_filename +
    //       "." +
    //       this.imageFileExtension;
    //     try {
    //       // Use ApiService to upload the image file to the backend with the desired filename
    //       await ApiService.uploadFile(
    //         "/images/image",
    //         selectedImageFile,
    //         thisfilename
    //       );
    //     } catch (error) {
    //       alert("Error uploading image: " + error);
    //     }
    //   }

    //   // update the record in the database
    //   const metaData = {
    //     crackingScore: this.crackingScore,
    //     drynessScore: this.drynessScore,
    //     erythemaScore: this.erythemaScore,
    //     excoriationScore: this.excoriationScore,
    //     exudationScore: this.exudationScore,
    //     lichenificationScore: this.lichenificationScore,
    //     oedemaScore: this.oedemaScore,
    //     imageQualityValue: this.imageQualityValue,
    //     imageTypeValue: this.imageTypeValue,
    //     imageLocationValue: this.imageLocationValue,
    //     imageRepresentativeSiteValue: this.imageRepresentativeSiteValue,
    //     imageAndroidValue: this.imageAndroidValue,
    //     questionnaireId: this.$route.params.uuid,
    //   };
    //   const record = {
    //     id: 1,
    //     filePath: "/Users/",
    //     image_extension: this.imageFileExtension,
    //     metaData: metaData,
    //   };

    //   // put the record into the database
    //   ApiService.put(
    //     "/media/answers/patient/" +
    //       this.$route.params.id +
    //       "/" +
    //       this.editing_uuid,
    //     record
    //   )
    //     .then(({}) => {
    //       alert("Successfully updated image record.");
    //     })
    //     .catch(({ response }) => {
    //       alert("Error: " + response.data.message);
    //     });

    //   // in the next part of the code, we'll refresh the displayed data on the UI
    //   // first, put the record into the current arrays
    //   this.severity_items[this.editing_severity_idx] = {
    //     crackingScore: this.crackingScore,
    //     drynessScore: this.drynessScore,
    //     erythemaScore: this.erythemaScore,
    //     excoriationScore: this.excoriationScore,
    //     exudationScore: this.exudationScore,
    //     lichenificationScore: this.lichenificationScore,
    //     oedemaScore: this.oedemaScore,
    //     imageQualityValue: this.imageQualityValue,
    //     imageTypeValue: this.imageTypeValue,
    //     imageLocationValue: this.imageLocationValue,
    //     imageRepresentativeSiteValue: this.imageRepresentativeSiteValue,
    //     imageAndroidValue: this.imageAndroidValue,
    //   };

    //   // replace the image extension in case there was a change of file format
    //   this.all_records[this.editing_severity_idx].image_extension =
    //     this.imageFileExtension;

    //   // replace the image data in the local_images array
    //   this.local_images[this.editing_image_idx] = this.image_data;
    //   if (this.image_data) {
    //     this.new_items[this.editing_severity_idx] = {
    //       img_idx: this.editing_image_idx,
    //       severity_idx: this.editing_severity_idx,
    //       imgsrc: this.local_images[this.editing_image_idx].src,
    //       cracking:
    //         this.severity_items[this.editing_severity_idx].crackingScore,
    //       dryness: this.severity_items[this.editing_severity_idx].drynessScore,
    //       erythema:
    //         this.severity_items[this.editing_severity_idx].erythemaScore,
    //       excoriation:
    //         this.severity_items[this.editing_severity_idx].excoriationScore,
    //       exudation:
    //         this.severity_items[this.editing_severity_idx].exudationScore,
    //       lichenification:
    //         this.severity_items[this.editing_severity_idx].lichenificationScore,
    //       oedema: this.severity_items[this.editing_severity_idx].oedemaScore,
    //       imageQualityValue:
    //         this.severity_items[this.editing_severity_idx].imageQualityValue,
    //       imageTypeValue:
    //         this.severity_items[this.editing_severity_idx].imageTypeValue,
    //       imageLocationValue:
    //         this.severity_items[this.editing_severity_idx].imageLocationValue,
    //       imageRepresentativeSiteValue:
    //         this.severity_items[this.editing_severity_idx]
    //           .imageRepresentativeSiteValue,
    //       imageAndroidValue:
    //         this.severity_items[this.editing_severity_idx].imageAndroidValue,
    //       imageFileExtension:
    //         this.all_records[this.editing_severity_idx].image_extension,
    //       record_uuid: this.all_records[this.editing_severity_idx].id,
    //     };
    //   } else {
    //     // by right, this should not be needed anymore since we alert the user to select an image if it is not selected, at the start of the function.
    //     this.new_items[this.editing_severity_idx] = {
    //       img_idx: this.editing_image_idx,
    //       severity_idx: this.editing_severity_idx,
    //       imgsrc: null,
    //       cracking:
    //         this.severity_items[this.editing_severity_idx].crackingScore,
    //       dryness: this.severity_items[this.editing_severity_idx].drynessScore,
    //       erythema:
    //         this.severity_items[this.editing_severity_idx].erythemaScore,
    //       excoriation:
    //         this.severity_items[this.editing_severity_idx].excoriationScore,
    //       exudation:
    //         this.severity_items[this.editing_severity_idx].exudationScore,
    //       lichenification:
    //         this.severity_items[this.editing_severity_idx].lichenificationScore,
    //       oedema: this.severity_items[this.editing_severity_idx].oedemaScore,
    //       imageQualityValue:
    //         this.severity_items[this.editing_severity_idx].imageQualityValue,
    //       imageTypeValue:
    //         this.severity_items[this.editing_severity_idx].imageTypeValue,
    //       imageLocationValue:
    //         this.severity_items[this.editing_severity_idx].imageLocationValue,
    //       imageRepresentativeSiteValue:
    //         this.severity_items[this.editing_severity_idx]
    //           .imageRepresentativeSiteValue,
    //       imageAndroidValue:
    //         this.severity_items[this.editing_severity_idx].imageAndroidValue,
    //       imageFileExtension:
    //         this.all_records[this.editing_severity_idx].image_extension,
    //       record_uuid: this.all_records[this.editing_severity_idx].id,
    //     };
    //   }

    //   // refresh the v-data-table
    //   await this.$forceUpdate();

    //   // exit
    //   this.updateTable();
    //   this.onCancelUpdateImageRecord();

    //   // this.refresh() updates the thumbnail images correctly.
    //   this.refresh();
    // },
    onCancelUpdateImageRecord() {
      this.is_editing_image_severity = false;
      this.editing_image_idx = null;
      this.editing_severity_idx = null;
      this.editing_uuid = null;
      this.$refs.pictureInput.removeImage();
      this.resetSeverityScores();
    },
    async onImageLoad(indata) {
      // check if data is none
      if (indata) {
        // check if data is an array
        if (Array.isArray(indata)) {
          // uploading multiple images
          // for each image, upload the image file to the server
          for (let i = 0; i < indata[0].length; i++) {
            if (this.is_editing_image_severity) {
              this.image_data = indata[0][i];
              const file = indata[1][i];
              const newImageFileExtension = file.name.split(".").pop();

              // check if the file extension has changed. If so, delete the old file.
              if (this.imageFileExtension != newImageFileExtension) {
                const oldfilename =
                  this.all_records[this.editing_severity_idx].image_filename +
                  "." +
                  this.imageFileExtension;
                // delete the old file
                try {
                  await ApiService.delete("/images/image/" + oldfilename);
                } catch (error) {
                  alert("Error deleting the previous image: " + error);
                }
              }

              // get file extension
              this.imageFileExtension = newImageFileExtension;
              const thisfilename =
                this.all_records[this.editing_severity_idx].image_filename +
                "." +
                this.imageFileExtension;
              try {
                // Use ApiService to upload the image file to the backend with the desired filename
                await ApiService.uploadFile(
                  "/images/image",
                  file,
                  thisfilename
                );
              } catch (error) {
                alert("Error uploading image: " + error);
              }
              
              // update the record in the database
              const metaData = {
                crackingScore: this.crackingScore,
                drynessScore: this.drynessScore,
                erythemaScore: this.erythemaScore,
                excoriationScore: this.excoriationScore,
                exudationScore: this.exudationScore,
                lichenificationScore: this.lichenificationScore,
                oedemaScore: this.oedemaScore,
                imageQualityValue: this.imageQualityValue,
                imageTypeValue: this.imageTypeValue,
                imageLocationValue: this.imageLocationValue,
                imageRepresentativeSiteValue: this.imageRepresentativeSiteValue,
                imageAndroidValue: this.imageAndroidValue,
                questionnaireId: this.$route.params.uuid,
              };
              const record = {
                id: 1,
                filePath: "/Users/",
                image_extension: this.imageFileExtension,
                metaData: metaData,
              };

              // put the record into the database
              ApiService.put(
                "/media/answers/patient/" +
                  this.$route.params.id +
                  "/" +
                  this.editing_uuid,
                record
              )
                .then(({}) => {
                  alert("Successfully updated image record.");
                })
                .catch(({ response }) => {
                  alert("Error: " + response.data.message);
                });
              
              // in the next part of the code, we'll refresh the displayed data on the UI
              // first, put the record into the current arrays
              this.severity_items[this.editing_severity_idx] = {
                crackingScore: this.crackingScore,
                drynessScore: this.drynessScore,
                erythemaScore: this.erythemaScore,
                excoriationScore: this.excoriationScore,
                exudationScore: this.exudationScore,
                lichenificationScore: this.lichenificationScore,
                oedemaScore: this.oedemaScore,
                imageQualityValue: this.imageQualityValue,
                imageTypeValue: this.imageTypeValue,
                imageLocationValue: this.imageLocationValue,
                imageRepresentativeSiteValue: this.imageRepresentativeSiteValue,
                imageAndroidValue: this.imageAndroidValue,
              };

              // replace the image extension in case there was a change of file format
              this.all_records[this.editing_severity_idx].image_extension =
                this.imageFileExtension;

              // replace the image data in the local_images array
              this.local_images[this.editing_image_idx] = this.image_data;
              if (this.image_data) {
                this.new_items[this.editing_severity_idx] = {
                  img_idx: this.editing_image_idx,
                  severity_idx: this.editing_severity_idx,
                  imgsrc: this.local_images[this.editing_image_idx].src,
                  cracking:
                    this.severity_items[this.editing_severity_idx]
                      .crackingScore,
                  dryness:
                    this.severity_items[this.editing_severity_idx].drynessScore,
                  erythema:
                    this.severity_items[this.editing_severity_idx]
                      .erythemaScore,
                  excoriation:
                    this.severity_items[this.editing_severity_idx]
                      .excoriationScore,
                  exudation:
                    this.severity_items[this.editing_severity_idx]
                      .exudationScore,
                  lichenification:
                    this.severity_items[this.editing_severity_idx]
                      .lichenificationScore,
                  oedema:
                    this.severity_items[this.editing_severity_idx].oedemaScore,
                  imageQualityValue:
                    this.severity_items[this.editing_severity_idx]
                      .imageQualityValue,
                  imageTypeValue:
                    this.severity_items[this.editing_severity_idx]
                      .imageTypeValue,
                  imageLocationValue:
                    this.severity_items[this.editing_severity_idx]
                      .imageLocationValue,
                  imageRepresentativeSiteValue:
                    this.severity_items[this.editing_severity_idx]
                      .imageRepresentativeSiteValue,
                  imageAndroidValue:
                    this.severity_items[this.editing_severity_idx]
                      .imageAndroidValue,
                  imageFileExtension:
                    this.all_records[this.editing_severity_idx].image_extension,
                  record_uuid: this.all_records[this.editing_severity_idx].id,
                };
              } else {
                // by right, this should not be needed anymore since we alert the user to select an image if it is not selected, at the start of the function.
                this.new_items[this.editing_severity_idx] = {
                  img_idx: this.editing_image_idx,
                  severity_idx: this.editing_severity_idx,
                  imgsrc: null,
                  cracking:
                    this.severity_items[this.editing_severity_idx]
                      .crackingScore,
                  dryness:
                    this.severity_items[this.editing_severity_idx].drynessScore,
                  erythema:
                    this.severity_items[this.editing_severity_idx]
                      .erythemaScore,
                  excoriation:
                    this.severity_items[this.editing_severity_idx]
                      .excoriationScore,
                  exudation:
                    this.severity_items[this.editing_severity_idx]
                      .exudationScore,
                  lichenification:
                    this.severity_items[this.editing_severity_idx]
                      .lichenificationScore,
                  oedema:
                    this.severity_items[this.editing_severity_idx].oedemaScore,
                  imageQualityValue:
                    this.severity_items[this.editing_severity_idx]
                      .imageQualityValue,
                  imageTypeValue:
                    this.severity_items[this.editing_severity_idx]
                      .imageTypeValue,
                  imageLocationValue:
                    this.severity_items[this.editing_severity_idx]
                      .imageLocationValue,
                  imageRepresentativeSiteValue:
                    this.severity_items[this.editing_severity_idx]
                      .imageRepresentativeSiteValue,
                  imageAndroidValue:
                    this.severity_items[this.editing_severity_idx]
                      .imageAndroidValue,
                  imageFileExtension:
                    this.all_records[this.editing_severity_idx].image_extension,
                  record_uuid: this.all_records[this.editing_severity_idx].id,
                };
              }
            } else {
              this.image_data = indata[0][i];
              const file = indata[1][i];
              this.imageFileExtension = file.name.split(".").pop();

              // send the record to the database
              const metaData = {
                crackingScore: null,
                drynessScore: null,
                erythemaScore: null,
                excoriationScore: null,
                exudationScore: null,
                lichenificationScore: null,
                oedemaScore: null,
                imageQualityValue: null,
                imageTypeValue: null,
                imageLocationValue: null,
                imageRepresentativeSiteValue: null,
                imageAndroidValue: null,
                questionnaireId: this.$route.params.uuid,
              };
              const record = {
                id: 1,
                filePath: "/Users/",
                image_extension: this.imageFileExtension,
                metaData: metaData,
              };

              var new_image_filename = null;
              try {
                const { data } = await ApiService.post(
                  "/media/answers/patient/" + this.$route.params.id,
                  record
                );
                this.all_records.push(data.data);
                this.severity_items.push(data.data.metaData);
                this.local_images_keys.push(data.data.image_filename);
                new_image_filename = data.data.image_filename;
              } catch ({ response }) {
                alert("Error: " + response.data.message);
              }

              // record the uuid file name
              const thisfilename =
                new_image_filename + "." + this.imageFileExtension;

              // upload the image file to the server
              try {
                // Use ApiService to upload the image file to the backend with the desired filename
                await ApiService.uploadFile(
                  "/images/image",
                  file,
                  thisfilename
                );
              } catch (error) {
                alert("Error uploading image: " + error);
              }

              // update the local_images array
              this.local_images.push(this.image_data); // doesn't work if we place it within the .then( ... ) block above
            }
            this.$refs.pictureInput.removeImage();
            this.resetSeverityScores();
            this.refresh();
          }
        } else {
          // if data is not an array, set the image_data to data

          // receive image data from PictureInput child component
          // we can access the image with image_data.src
          this.image_data = data;
        }
      } else {
        if (this.is_editing_image_severity) {
          this.is_editing_image_severity = false;
        }
      }
    },
    check_condition(data) {
      if (data.imgsrc) {
        return true;
      } else {
        return false;
      }
    },
    confirmTableDelete(item) {
      if (
        confirm(
          "Are you sure you want to delete this image and severity scores?"
        )
      ) {
        if (this.is_editing_image_severity) {
          this.is_editing_image_severity = false;
        }
        // If user confirms, delete the record from the database
        const index = this.new_items.indexOf(item);
        if (index !== -1) {
          this.new_items.splice(index, 1);
          this.all_records.splice(index, 1);
          this.severity_items.splice(index, 1);
          this.local_images.splice(index, 1);
          this.local_images_keys.splice(index, 1);

          // delete record from the database
          ApiService.delete(
            "/media/answers/patient/" +
              this.$route.params.id +
              "/" +
              item.record_uuid
          )
            .then(({}) => {
              alert("Successfully deleted image record.");
            })
            .catch(({ response }) => {
              alert("Error: " + response.data.message);
            });
        }
      }
      this.$refs.pictureInput.removeImage();
      this.resetSeverityScores();
    },
    // getImageLocationText(value) {
    //   return this.imageLocationOptions.find((option) => option.value == value)
    //     .text;
    // },
    updateTable() {
      this.new_items = [];
      for (let i = 0; i < this.severity_items.length; i++) {
        const this_image_filename = this.all_records[i].image_filename;
        const index = this.local_images_keys.indexOf(this_image_filename);
        if (this.local_images[index]) {
          this.new_items.push({
            img_idx: index,
            severity_idx: i,
            imgsrc: this.local_images[index].src,
            cracking: this.severity_items[i].crackingScore,
            dryness: this.severity_items[i].drynessScore,
            erythema: this.severity_items[i].erythemaScore,
            excoriation: this.severity_items[i].excoriationScore,
            exudation: this.severity_items[i].exudationScore,
            lichenification: this.severity_items[i].lichenificationScore,
            oedema: this.severity_items[i].oedemaScore,
            imageQualityValue: this.severity_items[i].imageQualityValue,
            imageTypeValue: this.severity_items[i].imageTypeValue,
            imageLocationValue: this.severity_items[i].imageLocationValue,
            imageRepresentativeSiteValue:
              this.severity_items[i].imageRepresentativeSiteValue,
            imageAndroidValue: this.severity_items[i].imageAndroidValue,
            imageFileExtension: this.all_records[i].image_extension,
            record_uuid: this.all_records[i].id,
          });
        } else {
          this.new_items.push({
            img_idx: index,
            severity_idx: i,
            imgsrc: null,
            cracking: this.severity_items[i].crackingScore,
            dryness: this.severity_items[i].drynessScore,
            erythema: this.severity_items[i].erythemaScore,
            excoriation: this.severity_items[i].excoriationScore,
            exudation: this.severity_items[i].exudationScore,
            lichenification: this.severity_items[i].lichenificationScore,
            oedema: this.severity_items[i].oedemaScore,
            imageQualityValue: this.severity_items[i].imageQualityValue,
            imageTypeValue: this.severity_items[i].imageTypeValue,
            imageLocationValue: this.severity_items[i].imageLocationValue,
            imageRepresentativeSiteValue:
              this.severity_items[i].imageRepresentativeSiteValue,
            imageAndroidValue: this.severity_items[i].imageAndroidValue,
            imageFileExtension: this.all_records[i].image_extension,
            record_uuid: this.all_records[i].id,
          });
        }
      }
    },
    resetSeverityScores() {
      this.crackingScore = null;
      this.drynessScore = null;
      this.erythemaScore = null;
      this.excoriationScore = null;
      this.exudationScore = null;
      this.lichenificationScore = null;
      this.oedemaScore = null;
      this.imageQualityValue = null;
      this.imageTypeValue = null;
      this.imageLocationValue = null;
      this.imageRepresentativeSiteValue = null;
      this.imageAndroidValue = null;
      this.imageFileExtention = null;
    },
    // async onAddImageRecord() {
    //   // check if the image is selected
    //   if (!this.image_data) {
    //     alert("Please select an image.");
    //     return false;
    //   }

    //   // check the input fields are filled in
    //   // var errors = [];
    //   // if (this.imageQualityValue == null) errors.push("Image Quality");
    //   // if (this.imageTypeValue == null) errors.push("Image Type");
    //   // if (this.imageLocationValue == null) errors.push("Image Location");
    //   // if (this.imageRepresentativeSiteValue == null) errors.push("Image Representative Site");
    //   // if (this.imageAndroidValue == null) errors.push("Image Android");
    //   // if (this.crackingScore == null) errors.push("Cracking");
    //   // if (this.drynessScore == null) errors.push("Dryness");
    //   // if (this.erythemaScore == null) errors.push("Erythema");
    //   // if (this.excoriationScore == null) errors.push("Excoriation");
    //   // if (this.exudationScore == null) errors.push("Exudation");
    //   // if (this.lichenificationScore == null) errors.push("Lichenification");
    //   // if (this.oedemaScore == null) errors.push("Oedema");

    //   // // prompt user to fill in all the required fields
    //   // if (errors.length > 0) {
    //   //   let alert_text = "Please fill in all the required fields: ";

    //   //   // write each error on a new line
    //   //   for (var i = 0; i < errors.length; i++) {
    //   //     alert_text += "\n \u2022" + errors[i];
    //   //   }

    //   //   alert(alert_text);
    //   //   return false;
    //   // }

    //   // get the image and file extension
    //   const pictureInput = this.$refs.pictureInput;
    //   const selectedImageFile = pictureInput.file;
    //   this.imageFileExtension = selectedImageFile.name.split(".").pop();

    //   // send the record to the database
    //   const metaData = {
    //     crackingScore: this.crackingScore,
    //     drynessScore: this.drynessScore,
    //     erythemaScore: this.erythemaScore,
    //     excoriationScore: this.excoriationScore,
    //     exudationScore: this.exudationScore,
    //     lichenificationScore: this.lichenificationScore,
    //     oedemaScore: this.oedemaScore,
    //     imageQualityValue: this.imageQualityValue,
    //     imageTypeValue: this.imageTypeValue,
    //     imageLocationValue: this.imageLocationValue,
    //     imageRepresentativeSiteValue: this.imageRepresentativeSiteValue,
    //     imageAndroidValue: this.imageAndroidValue,
    //     questionnaireId: this.$route.params.uuid,
    //   };
    //   const record = {
    //     id: 1,
    //     filePath: "/Users/",
    //     image_extension: this.imageFileExtension,
    //     metaData: metaData,
    //   };

    //   var new_image_filename = null;
    //   try {
    //     const { data } = await ApiService.post(
    //       "/media/answers/patient/" + this.$route.params.id,
    //       record
    //     );
    //     this.all_records.push(data.data);
    //     this.severity_items.push(data.data.metaData);
    //     this.local_images_keys.push(data.data.image_filename);
    //     new_image_filename = data.data.image_filename;
    //   } catch ({ response }) {
    //     alert("Error: " + response.data.message);
    //   }

    //   // record the uuid file name
    //   const thisfilename = new_image_filename + "." + this.imageFileExtension;

    //   // upload the image file to the server
    //   try {
    //     // Use ApiService to upload the image file to the backend with the desired filename
    //     await ApiService.uploadFile(
    //       "/images/image",
    //       selectedImageFile,
    //       thisfilename
    //     );
    //   } catch (error) {
    //     alert("Error uploading image: " + error);
    //   }

    //   // update the local_images array
    //   this.local_images.push(this.image_data); // doesn't work if we place it within the .then( ... ) block above
    //   this.$refs.pictureInput.removeImage();
    //   this.resetSeverityScores();
    //   this.refresh();
    // },
    findEASImaxvalue(field) {
      // six for region score
      if (field.key == "region_score") return "6";
      // 1 for multiplier
      else if (field.key == "multiplier") return "1";
      // 3 for the severity scores
      else return "3";
    },
    checkEASItablevalue(field, value) {
      const invalue = Number(value);
      const max = Number(this.findEASImaxvalue(field));
      if (invalue < 0 || invalue > max) {
        // alert("Please enter a value between 0 and " + max);
        return false;
      }
      if (field.key != "region_score" && field.key != "multiplier") {
        // 0.5 is not permitted - if a sign is present, it should be at least mild (1)
        if ([0, 1, 1.5, 2, 2.5, 3].includes(invalue)) {
          return null;
        } else {
          return false;
        }
      } else if (field.key == "region_score") {
        if ([0, 1, 2, 3, 4, 5, 6].includes(invalue)) {
          return null;
        } else {
          return false;
        }
      } else {
        return null;
      }
    },
    findEASIstepvalue(field) {
      // 0.1 for region score or multiplier
      if (field.key == "region_score") {
        return "1";
      } else if (field.key == "multiplier") {
        return "0.1";
      }
      // 0.5 for the severity scores
      else return "0.5";
    },
    // EASI
    easiCheckValidValues() {
      // check if all fields are filled, return true if there is no error
      var easi_valid_severity_list = [0, 1, 1.5, 2, 2.5, 3];
      var easi_valid_region_list = [0, 1, 2, 3, 4, 5, 6];
      for (let i = 0; i < this.easi_items.length; i++) {
        if (
          !easi_valid_severity_list.includes(
            parseFloat(this.easi_items[i].erythema)
          ) ||
          !easi_valid_severity_list.includes(
            parseFloat(this.easi_items[i].edema)
          ) ||
          !easi_valid_severity_list.includes(
            parseFloat(this.easi_items[i].excoriation)
          ) ||
          !easi_valid_severity_list.includes(
            parseFloat(this.easi_items[i].lichenification)
          ) ||
          !easi_valid_region_list.includes(
            parseFloat(this.easi_items[i].region_score)
          )
        ) {
          return false;
        }
        return true;
      }
    },
    updateMannequin(data) {
      this.mannequin_update = data;
      this.computeSCORADExtentTotalValue();
    },
    updateRegionsMannequin(regions) {
      this.mannequin_regions = regions;
    },
    selectedRegionsMannequin(regions) {
      if (Object.keys(regions).length > 0) {
        this.mannequin_selected_regions_backup =
          this.mannequin_selected_regions;
      }
      this.mannequin_selected_regions = regions;
    },
    toggleNoRegionMannequin(data) {
      this.mannequin_no_region = data;
      this.computeSCORADExtentTotalValue();
    },
    // genitalsSelectedMannequin (data) {
    //   previously called at Mannequin component, v-on:genitals-selected="genitalsSelectedMannequin"
    // },
    computeSCORADExtentTotalValue() {
      if (this.mannequin_no_region) {
        this.extentValue = 0;
        this.extentText =
          "The button No Region Affected has been selected. SCORAD extent score is 0.";
        return;
      }

      if (this.mannequin_selected_regions == null) {
        this.extentValue = null;
        this.extentText = "No regions in the mannequin has been selected.";
        return;
      }

      if (this.patient_age_at_visit == null) {
        this.extentText =
          "Patient's age at visit is not available. Please check patient's birthday.";
        return;
      }

      if (Object.keys(this.mannequin_selected_regions).length > 0) {
        var total = 0;

        if (this.patient_age_at_visit < 2) {
          // front
          for (
            let i = 0;
            i < this.mannequin_selected_regions["front"].length;
            i++
          ) {
            if (this.mannequin_selected_regions["front"][i]) {
              total += this.mannequin_area_score_below_2["front"][i];
            }
          }
          // back
          for (
            let i = 0;
            i < this.mannequin_selected_regions["back"].length;
            i++
          ) {
            if (this.mannequin_selected_regions["back"][i]) {
              total += this.mannequin_area_score_below_2["back"][i];
            }
          }
        } else if (this.patient_age_at_visit >= 2) {
          // front
          for (
            let i = 0;
            i < this.mannequin_selected_regions["front"].length;
            i++
          ) {
            if (this.mannequin_selected_regions["front"][i]) {
              total += this.mannequin_area_score_2_and_above["front"][i];
            }
          }
          // back
          for (
            let i = 0;
            i < this.mannequin_selected_regions["back"].length;
            i++
          ) {
            if (this.mannequin_selected_regions["back"][i]) {
              total += this.mannequin_area_score_2_and_above["back"][i];
            }
          }
        }
        if (total == 0) {
          this.extentValue = null;
          this.extentText = "No regions in the mannequin has been selected.";
          return;
        } else {
          this.extentValue = total;
          this.extentText = "SCORAD extent score is " + total + ".";
          return;
        }
      } else {
        this.extentValue = null;
        this.extentText =
          "Unable to compute the SCORAD extent score. Please check the regions in the mannequin.";
        return;
      }
    },
    async selectSCORADOption(index, qnum) {
      this.localSCORADSelectedOption[qnum] = index;
      if (qnum == 0) {
        this.scoradErythemaIntensityValue = index;
      } else if (qnum == 1) {
        this.scoradEdemaIntensityValue = index;
      } else if (qnum == 2) {
        this.scoradOozingIntensityValue = index;
      } else if (qnum == 3) {
        this.scoradExcoradIntensityValue = index;
      } else if (qnum == 4) {
        this.scoradLichenificationIntensityValue = index;
      } else if (qnum == 5) {
        this.scoradDrynessIntensityValue = index;
      }
      // there was a problem where the option-box does not display properly when clicking the first option (i.e. left most option box) the first time.
      // the option-box appearance works properly only when clicking on a non-first option.
      // or the selected first option of that question suddenly appears when we select a non-first option on another question subsequently.
      // it seems as though :class in div is not triggered, when the first option is clicked the first time.
      // forceUpdate solves a problem
      await this.$forceUpdate();
    },
    async selectPOEMOption(index, qnum) {
      this.localPOEMSelectedOption[qnum] = index;
      if (qnum == 0) {
        this.q1ItchyEczemaValue = index;
      } else if (qnum == 1) {
        this.q2SleepDisturbedValue = index;
      } else if (qnum == 2) {
        this.q3BleedingValue = index;
      } else if (qnum == 3) {
        this.q4WeepingValue = index;
      } else if (qnum == 4) {
        this.q5CrackedValue = index;
      } else if (qnum == 5) {
        this.q6DryValue = index;
      } else if (qnum == 6) {
        this.q7FlakingValue = index;
      }
      await this.$forceUpdate();
    },
    onSave(tab_text) {
      // check if data is provided for all required fields
      var errors = [];
      if (tab_text == "Image collection information") {
        if (this.recordCompletionValue == null) {
          errors.push("Record completion");
        }
        if (this.lightingValue == null) {
          errors.push("Lighting");
        }
        if (this.numScorerValue == null) {
          errors.push("Number of scorers");
        }
      }
      if (tab_text == "EASI") {
        if (this.easiCheckValidValues() == false) {
          errors.push("EASI table contains invalid value(s).");
        }
        for (let i = 0; i < this.easi_items.length; i++) {
          if (
            isNaN(this.easi_items[i].total) ||
            this.easi_items[i].total == null
          ) {
            errors.push(this.easi_items[i].body_region);
          }
        }
      }
      if (tab_text == "BSA") {
        if (this.$refs["bsaHeadNeck"].validity.valid == false) {
          errors.push("Body surface area, Head & Neck field contains error.");
        }
        if (this.$refs["bsaTrunk"].validity.valid == false) {
          errors.push("Body surface area, Trunk field contains error.");
        }
        if (this.$refs["bsaUpperLimbs"].validity.valid == false) {
          errors.push("Body surface area, Upper Limbs field contains error.");
        }
        if (this.$refs["bsaLowerLimbs"].validity.valid == false) {
          errors.push("Body surface area, Lower Limbs field contains error.");
        }
      }
      if (tab_text == "POEM") {
        if (this.completedByValue == null) {
          errors.push("Completed by");
        }
        if (this.q1ItchyEczemaValue == null) {
          errors.push("Q1: Itchy");
        }
        if (this.q2SleepDisturbedValue == null) {
          errors.push("Q2: Sleep disturbed");
        }
        if (this.q3BleedingValue == null) {
          errors.push("Q3: Bleeding");
        }
        if (this.q4WeepingValue == null) {
          errors.push("Q4: Weeping / oozing");
        }
        if (this.q5CrackedValue == null) {
          errors.push("Q5: Cracked");
        }
        if (this.q6DryValue == null) {
          errors.push("Q6: Dry");
        }
        if (this.q7FlakingValue == null) {
          errors.push("Q7: Flaking");
        }
      }
      // IGA. Currently, we do not require any value in IGA tab to be compulsory.
      if (tab_text == "Clinical Information") {
        if (this.clinicalInfoMoisturiserValue == null) {
          errors.push("Moisturiser / emollient");
        }
        if (this.clinicalInfoMoisturiserValue == "<4 hours") {
          if (
            this.clinicalInfoMoisturiserLessThan4HoursText == null ||
            this.clinicalInfoMoisturiserLessThan4HoursText == ""
          ) {
            errors.push("Moisturiser / emollient: <4 hours");
          }
        }
        if (this.clinicalInfoPresentationExtensoFlexorValue == null) {
          errors.push("Extensor / flexor");
        }
        // if (this.clinicalInfoPresentationDiscoidValue == null) {
        //   errors.push("Discoid");
        // }
        // if (this.clinicalInfoPresentationDiscoidValue == "Yes") {
        //   if (
        //     this.clinicalInfoPresentationDiscoidText == null ||
        //     this.clinicalInfoPresentationDiscoidText == ""
        //   ) {
        //     errors.push("Discoid: which image numbers?");
        //   }
        // }
        // if (this.clinicalInfoPresentationPrurigoNodularisValue == null) {
        //   errors.push("Prurigo nodularis");
        // }
        // if (this.clinicalInfoPresentationPrurigoNodularisValue == "Yes") {
        //   if (
        //     this.clinicalInfoPresentationPrurigoNodularisText == null ||
        //     this.clinicalInfoPresentationPrurigoNodularisText == ""
        //   ) {
        //     errors.push("Prurigo nodularis: which image numbers?");
        //   }
        // }
        // if (this.clinicalInfoPresentationPompholyxValue == null) {
        //   errors.push("Pompholyx");
        // }
        // if (this.clinicalInfoPresentationPompholyxValue == "Yes") {
        //   if (
        //     this.clinicalInfoPresentationPompholyxText == null ||
        //     this.clinicalInfoPresentationPompholyxText == ""
        //   ) {
        //     errors.push("Pompholyx: which image numbers?");
        //   }
        // }
        // if (this.clinicalInfoPresentationFollicularValue == null) {
        //   errors.push("Follicular");
        // }
        // if (this.clinicalInfoPresentationFollicularValue == "Yes") {
        //   if (
        //     this.clinicalInfoPresentationFollicularText == null ||
        //     this.clinicalInfoPresentationFollicularText == ""
        //   ) {
        //     errors.push("Follicular: which image numbers?");
        //   }
        // }
        // if (this.clinicalInfoPresentationCommentsValue == null) {
        //   errors.push("Other Comments");
        // }
        // if (this.clinicalInfoPresentationCommentsValue == "Yes") {
        //   if (
        //     this.clinicalInfoPresentationCommentsText == null ||
        //     this.clinicalInfoPresentationCommentsText == ""
        //   ) {
        //     errors.push("Other Comments: please specify");
        //   }
        // }
        if (this.clinicalInfoAcuteInfectedValue == null) {
          errors.push("Acute infected");
        }
        if (this.clinicalInfoAcuteInfectedValue == "Yes") {
          if (this.clinicalInfoAcuteBacterialValue == null) {
            errors.push("Acute infected: bacterial");
          }
          if (this.clinicalInfoAcuteVirualValue == null) {
            errors.push("Acute infected: viral");
          }
        }
        if (this.clinicalInfoPatientAreasNotPhotographedValue == null) {
          errors.push("Areas not to have photographed");
        }
        if (this.clinicalInfoPatientAreasNotPhotographedValue == "Yes") {
          if (
            this.clinicalInfoPatientAreasNotPhotographedText == null ||
            this.clinicalInfoPatientAreasNotPhotographedText == ""
          ) {
            errors.push("Areas not to have photographed: please specify");
          }
        }
      }

      // prompt user to fill in all the required fields
      if (errors.length > 0) {
        let alert_text = "";
        if (tab_text != "BSA") {
          alert_text = "Please fill in all the required fields: ";
        }

        // write each error on a new line
        for (var i = 0; i < errors.length; i++) {
          alert_text += "\n \u2022" + errors[i];
        }

        alert(alert_text);
        return false;
      }

      const metaData = {
        // Images & Severity Scores
        recordCompletion: this.recordCompletionValue,
        lighting: this.lightingValue,
        numScorer: this.numScorerValue,
        // EASI
        easi_items: this.easi_items,
        // BSA
        mannequin_selected_regions: this.mannequin_selected_regions,
        mannequin_selected_regions_backup:
          this.mannequin_selected_regions_backup,
        mannequin_no_region: this.mannequin_no_region,
        extent: this.extentValue,
        scoradErythemaIntensity: this.scoradErythemaIntensityValue,
        scoradEdemaIntensity: this.scoradEdemaIntensityValue,
        scoradOozingIntensity: this.scoradOozingIntensityValue,
        scoradExcoradIntensity: this.scoradExcoradIntensityValue,
        scoradLichenificationIntensity:
          this.scoradLichenificationIntensityValue,
        scoradDrynessIntensity: this.scoradDrynessIntensityValue,
        // scoradRepresentativeSiteValue: this.scoradRepresentativeSiteValue,
        scoradBSAHeadNeck: this.scoradBSAHeadNeck,
        scoradBSATrunk: this.scoradBSATrunk,
        scoradBSAUpperLimbs: this.scoradBSAUpperLimbs,
        scoradBSALowerLimbs: this.scoradBSALowerLimbs,
        scoradPruritus: this.scoradPruritusValue,
        scoradSleep: this.scoradSleepValue,
        localSCORADSelected: this.localSCORADSelectedOption,
        // POEM
        completedByValue: this.completedByValue,
        q1ItchyEczema: this.q1ItchyEczemaValue,
        q2SleepDisturbed: this.q2SleepDisturbedValue,
        q3Bleeding: this.q3BleedingValue,
        q4Weeping: this.q4WeepingValue,
        q5Cracked: this.q5CrackedValue,
        q6Dry: this.q6DryValue,
        q7Flaking: this.q7FlakingValue,
        localPOEMSelected: this.localPOEMSelectedOption,
        // IGA
        igaValue: this.igaValue,
        // Clinical Information
        clinicalInfoMoisturiser: this.clinicalInfoMoisturiserValue,
        clinicalInfoMoisturiserLessThan4Hours:
          this.clinicalInfoMoisturiserLessThan4HoursText,
        clinicalInfoPresentationExtensoFlexor:
          this.clinicalInfoPresentationExtensoFlexorValue,
        clinicalInfoPresentationDiscoid:
          this.clinicalInfoPresentationDiscoidValue,
        clinicalInfoPresentationDiscoidText:
          this.clinicalInfoPresentationDiscoidText,
        clinicalInfoPresentationPrurigoNodularis:
          this.clinicalInfoPresentationPrurigoNodularisValue,
        clinicalInfoPresentationPrurigoNodularisText:
          this.clinicalInfoPresentationPrurigoNodularisText,
        clinicalInfoPresentationPompholyx:
          this.clinicalInfoPresentationPompholyxValue,
        clinicalInfoPresentationPompholyxText:
          this.clinicalInfoPresentationPompholyxText,
        clinicalInfoPresentationFollicular:
          this.clinicalInfoPresentationFollicularValue,
        clinicalInfoPresentationFollicularText:
          this.clinicalInfoPresentationFollicularText,
        clinicalInfoPresentationComments:
          this.clinicalInfoPresentationCommentsValue,
        clinicalInfoPresentationCommentsText:
          this.clinicalInfoPresentationCommentsText,
        clinicalInfoAcuteInfected: this.clinicalInfoAcuteInfectedValue,
        clinicalInfoAcuteBacterial: this.clinicalInfoAcuteBacterialValue,
        clinicalInfoAcuteVirual: this.clinicalInfoAcuteVirualValue,
        clinicalInfoPatientAreasNotPhotographed:
          this.clinicalInfoPatientAreasNotPhotographedValue,
        clinicalInfoPatientAreasNotPhotographedText:
          this.clinicalInfoPatientAreasNotPhotographedText,
      };
      const record = {
        answer_json: this.recordCompletionValue,
        metaData: metaData,
      };
      ApiService.put(
        "/questionnaires/answers/patient/" +
          this.$route.params.id +
          "/" +
          this.$route.params.uuid,
        record
      ).catch((error) => {
        let error_msg = "An error occurred while saving the data. ";
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          error_msg += error.response.data.message;
        }
        alert(error_msg);
      });
      alert(tab_text + " record saved!");
    },
  },
};
</script>

<style scoped>
/* Override margins for specific elements */
.custom-margin-class {
  margin: 0; /* Adjust the margin value as needed */
}

.image-upload-container {
  box-sizing: border-box;
  width: 95%;
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background: rgba(6, 72, 150, 0.28);
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 2em 1em;
  /* margin: 15px; */
  font-family: "Mulish", sans-serif;
  margin: 2em;
  min-height: 400px;
}

.table-container {
  max-height: 60vh; /* Adjust as needed */
  overflow: hidden;
  text-align: center;
}

.table-body {
  max-height: 60vh; /* Adjust height to account for the header height */
  overflow-y: auto;
  text-align: center;
}

.table-body .v-data-table-header th {
  text-align: center !important;
}

.delete-button {
  cursor: pointer;
  color: #ff0000; /* Red color for the delete button */
  font-size: 1.2em; /* Adjust font size as needed */
}

.table-body-site {
  width: auto; /* Adjust the width as needed */
}

.severity-upload-container {
  box-sizing: border-box;
  width: 95%;
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background: rgba(6, 72, 150, 0.28);
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 2em 1em;
  /* margin: 15px; */
  font-family: "Mulish", sans-serif;
  margin: 2em;
  min-height: 100px;
}

.add-button-container {
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0.5em 2em 2em 2em;
}

.upload-area {
  min-height: 200px;
  max-height: 350px;
  flex: 0.4;
  background-color: #f8f8ff; /* or whatever color you prefer */
  border: 1px dashed rgba(56, 78, 183, 0.3);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.upload-icon {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-instructions {
  font-size: 1;
  font-weight: 700;
  margin-bottom: 0.5em;
  color: #0f0f0f;
  text-align: center;
}

.browse-button {
  background: none;
  color: #483ea8;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  text-decoration: underline;
}

.upload-support {
  font-size: 0.75em;
  font-weight: 400;
  margin-bottom: 0.5em;
  /* line-height: 18px; */
  color: #676767;
  text-align: center;
}

.uploading-images {
  flex: 0.6;
  margin-left: 1em;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  max-width: 60%;
  width: 60%;
}

/* .uploaded-image-bar {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.uploaded-image-bar:hover {
  border: 2px solid #6d5dfa;
} */

.confirm-overview-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8); /* semi-transparent white overlay */
  /* other styles... */
}

.upload-text {
  font-size: 0.875em;
  font-weight: bold;
  margin-bottom: 0.5em;
  color: #676767;
}

.image-missing {
  color: #e41d1d;
}

@media (max-width: 600px) {
  .image-upload-container {
    flex-direction: column;
    min-height: 400px;
  }

  .upload-area {
    max-height: 200px;
    flex: 1;
  }

  .uploading-images {
    box-sizing: border-box;
    flex: 1;
    margin: 0;
    max-width: 100%;
    width: 100%;
  }
}

h3 {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  margin-bottom: 1rem;
  color: #0f0f0f;
  text-align: center;
}

ul {
  padding-left: 2rem;
}

.b-form-select-25pcnt {
  width: 25%;
  margin-bottom: 0.5em;
}

.b-form-select-50pcnt {
  width: 50%;
  margin-bottom: 0.5em;
}

.options-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 8px;
  margin: 0 20px;
  font-weight: bold;
  width: 80%;
  min-width: 300px;
  max-width: 800px;
}

.option-box {
  border: 1px solid #686868;
  padding: 8px;
  text-align: center;
  cursor: pointer;
  flex: 1;
  min-width: 0; /* This line and the following two lines handle long text gracefully */
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 4px;
  border-radius: 8px;
  width: 60%;
}

.option-box.selected {
  background-color: #a4f1c3;
}

.table-image {
  width: 100px;
  object-fit: contain;
  padding: 5px;
}

input:invalid {
  border: 1px solid red;
}
select:invalid {
  border: 1px solid red;
}

.red-text {
  color: red;
}

/* Adjust card width and margins */
.custom-card {
  width: 100%; /* Make the card as wide as its container */
  margin: 0; /* Remove any margins */
}
</style>
